import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppSelector } from "../../app/hooks";
import useActions from "../../app/hooks";
import { RootState } from "../../app/rootReducer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { apiGetCategory } from "../../services/documentTemplatesAPI";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { apiGetOrganizations } from "../../services/organizationAPI";
import { apiGetWorkspaces } from "../../services/workspaceAPI";
import { selectAuth, selectUser } from "../auth/authSlice";
import { SUB_CATEGORY } from "../../globals";
export default function CreateDocumentTemplateForm(props: {
  open: boolean;
  once_off: boolean;
  parentCloseHandle: () => void;
}) {
  const [nameState, setNameState] = useState("");
  const [category, setCategory] = useState("");
  const [purpose, setPurpose] = useState("");
  const [categoryError, setCategoryError] = React.useState("Category");
  const [purposeError, setPurposeError] = React.useState("Purpose");
  const actions = useActions();
  const errors = useSelector((state: RootState) => state.library.error);
  const [categories, setCategories] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [organizationID, SetorganizationID] = useState(0);
  const [workspaceID, SetworkspaceID] = useState(0);
  const [isNameErrorState, setIsNameErrorState] = useState<boolean>(false);
  const auth = useAppSelector(selectAuth);

  const user = useAppSelector(selectUser);

  const default_organization_id =
    auth.user?.default_organization_id || user?.default_orgainization_id;

  const default_workspace_id =
    auth.user?.default_workspace_id || user?.default_workspace_id;

  // useEffect(() => {
  //   // async function getOrganization() {
  //   //   const response = await apiGetOrganizations();
  //   //   SetorganizationID(response.data.organizations[0].id);
  //   //   // console.log("response.data.organizations[0]", response.data)
  //   // }
  //   // async function getworkspace() {
  //   //   const response = await apiGetWorkspaces(organizationID);
  //   //   SetworkspaceID(response.data.workspaces[0].id);
  //   // }
  //   async function getList() {
  //     // const response = await apiGetCategory(organizationID, workspaceID);
  //     const response = await apiGetCategory(auth.user!.default_organization_id!,auth.user!.default_workspace_id!);
  //     setCategories(response.category_list);
  //     setPurposes(response.purpose_list);
  //     // console.log("response.data.organizations[0]",response)
  //   }
  //   try {
  //     // getOrganization();
  //     // getworkspace();
  //     getList();
  //   } catch (error) {
  //     console.error(
  //       "Error While calling get Organization, Workspace, getList",
  //       error
  //     );
  //   }
  // }, []);

  useEffect(() => {
    // async function getOrganization() {
    //   const response = await apiGetOrganizations();
    //   SetorganizationID(response.data.organizations[0].id);
    //   // console.log("response.data.organizations[0]", response.data)
    // }
    // async function getworkspace() {
    //   const response = await apiGetWorkspaces(organizationID);
    //   SetworkspaceID(response.data.workspaces[0].id);
    // }
    async function getList() {
      // const response = await apiGetCategory(organizationID, workspaceID);
      const response = await apiGetCategory(
        default_organization_id!,
        default_workspace_id!
      );
      setCategories(response.category_list);
      setPurposes(response.purpose_list);
      // console.log("response.data.organizations[0]",response)
    }
    try {
      // getOrganization();
      // getworkspace();
      if (props.open) {
        getList();
      }
    } catch (error) {
      console.error(
        "Error While calling get Organization, Workspace, getList",
        error
      );
    }
  }, [props.open]);

  useEffect(() => {
    if (String(errors) === "isLoading") {
      setIsNameErrorState(false);
    }
    if (String(errors) === "Conflict") {
      setIsNameErrorState(true);
    }

    if (errors === undefined) {
      setNameState("");
      setCategory("");
      setPurpose("");
      props.parentCloseHandle();
    }
  }, [errors]);

  const createDocumentTemplate = () => {
    if (isNameErrorState) return;
    const newTemplate = {
      name: nameState,
      category: category,
      purpose: purpose,
      title: nameState,
      body: "body",
      once_off: props.once_off,
    };
    actions.createDocumentTemplate(newTemplate);
  };

  function handleNameChange(e: any) {
    setIsNameErrorState(false);
    setNameState(e.target.value);
  }

  const handlePurposeChange = (e: any) => {
    if (e.target.value.trim() === "") {
      setPurposeError("Invalid purpose");
    } else setPurposeError("");
    setPurpose(e.target.value);
  };
  const handleOptionChange = (event: any, newValue: any) => {
    setCategory(newValue);
  };
  const handleOptionChangeP = (event: any, newValue: any) => {
    setPurpose(newValue);
  };

  function handleCategoryChange(e: any) {
    if (e.target.value.trim() === "") {
      setCategoryError("Invalid category");
    } else setCategoryError("");
    setCategory(e.target.value);
  }

  let nameErrorText;
  if (isNameErrorState) {
    if (nameState !== "") nameErrorText = "This name already exists";
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        setNameState("");
        props.parentCloseHandle();
      }}
    >
      <DialogTitle>
        {props.once_off ? "New Once-Off Document" : "New Document Template"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.once_off
            ? "Create a new once-off document"
            : "Create a new document template"}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          required
          onChange={handleNameChange}
          error={nameState === "" || isNameErrorState}
          value={nameState}
          helperText={
            nameState === ""
              ? "Name cannot be empty"
              : null || isNameErrorState
              ? nameErrorText
              : null
          }
        />
        <Autocomplete
          id="auto"
          freeSolo
          autoSelect
          options={categories}
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="category"
              label="Category"
              fullWidth
              required
              onChange={handleCategoryChange}
              // error={categoryError !== ""}
              error={category === ""}
              variant="standard"
            />
          )}
        />
        <Autocomplete
          id="autoc"
          freeSolo
          autoSelect
          options={purposes}
          onChange={handleOptionChangeP}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="purpose"
              label={SUB_CATEGORY}
              fullWidth
              required
              onChange={handlePurposeChange}
              error={purpose === ""}
              // error={purposeError!==""}
              variant="standard"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setNameState("");
            props.parentCloseHandle();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={createDocumentTemplate}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            nameState === "" ||
            isNameErrorState ||
            category === "" ||
            purpose === ""
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
