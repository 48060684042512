/* eslint-disable no-debugger */
import * as React from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import dayjs from "dayjs";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "@mui/material/Input";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Divider,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
} from "@mui/material";

import {
  selectWorkspaceSummaryState,
  setError,
} from "../libraryContent/workspaceSummarySlice";
import LibraryContentElementSubSection from "./LibraryContentElementSubSection";

import { Search, User } from "../../../../types/User";
import {
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
} from "../../../../types/DocumentTemplate";
import { selectEditingDocumentTemplate } from "../../editorSlice";
import { ViewPreferences } from "../../header/preferencesSlice";
import { CheckBox } from "@mui/icons-material";
import { apiGetWorkspaceSearch } from "../../../../services/workspaceAPI";
import utc from "dayjs/plugin/utc"; // Import the utc plugin
import "dayjs/locale/de";
import { apiGetCategory } from "../../../../services/documentTemplatesAPI";
import { selectAuth, selectUser } from "../../../auth/authSlice";
import { SUB_CATEGORY } from "../../../../globals";
import {
  setCompleteCrossReferencesData,
  setIsLibraryContentAvailable,
} from "src/features/crossReferencing/crossreferenceSlice";
import { useSelector } from "react-redux";
import { apiGetDocumentReferences } from "src/services/crossReferencingAPI";
import { RootState } from "src/app/rootReducer";
import useValidateReferences from "../libraryContent/useValidateReferences";

/**
 * Form for configuring library content.
 *
 * @export
 * @return {*}
 */
export const MyObjectContext = React.createContext<any>([]);
let timeoutId: NodeJS.Timeout | null = null;

export default function LibraryContentConfigFormSubSection(props: {
  closeDialogHandler?: any;
  organizationId: number;
  workspaceId: number;
  parentSubsection?: DocumentSubsection | null;
  parentSection?: DocumentSection | null;
  viewPreferences: ViewPreferences;
  isFloatingInsert?: boolean | undefined;
  sectionBelowInsert?: DocumentSection | null;
}) {
  const [selectedSubSection, setSelectedSubSection] = React.useState<any[]>([]);
  const workspaceSummaryState = useAppSelector(selectWorkspaceSummaryState);
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);

  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);

  const default_organization_id =
    auth.user?.default_organization_id || user?.default_orgainization_id;

  const default_workspace_id =
    auth.user?.default_workspace_id || user?.default_workspace_id;

  const actions = useActions();
  const dispatch = useAppDispatch();
  const [IsFilterOpen, SetIsFilterOpen] = useState<boolean>(false);
  const [IsOnceoffDocument, SetOnceoffDocument] = useState<boolean>(true);
  const [IsOtherWorkSpace, SetOtherWorkSpace] = useState<boolean>(false);
  const [IsArchivedShow, SetArchivedShow] = useState<boolean>(false);
  const [Purpose, SetPurpose] = useState("");
  const [Category, SetCategory] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const [selectedStartDateParse, setSelectedStartDateParse] = useState<any>("");
  const [selectedEndDateParse, setSelectedEndDateParse] = useState<any>("");
  const [searchString, setSearchString] = React.useState<string>("");
  const [searchList, setSearchList] = useState<any>([]);
  const [sectionBelowInsert, setSectionBelowInsert] = useState<
    any | undefined
  >();
  const sectionBelow = props.sectionBelowInsert!;
  const parentSubsection = props.parentSubsection!;
  const parentSection = props.parentSection!;
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(true);
  const [categoriesList, setCategoriesList] = useState<any>([]);
  const [purposesList, setPurposesList] = useState<any>([]);

  React.useEffect(() => {
    const getList = async () => {
      const response = await apiGetCategory(
        default_organization_id,
        default_workspace_id
      );
      setCategoriesList(response.category_list);
      setPurposesList(response.purpose_list);
    };
    try {
      getList();
    } catch (e: any) {
      console.warn("Unable to get Categories and Purpose List");
    }
  }, []);
  const handleReset = () => {
    SetOnceoffDocument(true);
    SetOtherWorkSpace(false);
    SetCategory("");
    SetPurpose("");
    setSelectedEndDateParse("");
    setSelectedStartDateParse("");
    SetArchivedShow(false);
  };
  const lastValue = React.useMemo(() => {
    return sectionBelow!;
    // return props.sectionBelowInsert;
  }, []);

  const disableFutureDates = (date: any) => {
    return date > new Date();
  };
  React.useEffect(() => {
    // On mounting get the latest summaries

    actions.getWorkspaceSummary();
  }, []);

  // Selected Document
  const [selectedDocumentSummary, setSelectedDocumentSummary] = React.useState<
    any | undefined
  >(undefined);

  //Selected Section
  const [selectedDocumentSectionSummary, setSelectedDocumentSectionSummary] =
    React.useState<DocumentSectionSummary | undefined>(undefined);
  //Close Library Content
  const handleCancel = () => {
    props.closeDialogHandler();
    dispatch(setIsLibraryContentAvailable(false));
  };

  const userState = useSelector(selectUser) as User;
  const isLibContentOpen = useSelector(
    (state: RootState) => state.crossReferencing.isLibContentOpen
  );
  interface Reference {
    id: number;
    number: string;
  }

  const getReferences = async (
    workspaceId: number,
    doc_id: number,
    selectedDoc: any
  ) => {
    const responseData: any = await apiGetDocumentReferences(
      userState.default_organization_id,
      workspaceId,
      doc_id
    );

    if (responseData.data) {
      const response: Record<string, string> = responseData.data;
      const result: Reference[] = Object.entries(response).map(
        ([key, number]) => {
          const id = parseInt(key.match(/\d+/)?.[0] || "0", 10);
          return { id, number };
        }
      );

      dispatch(setCompleteCrossReferencesData(result));
    }
  };

  const { workspaceSummaries } = workspaceSummaryState;
  const selectedDocument: any = workspaceSummaries?.find(
    (doc) => doc?.id === selectedDocumentSummary?.id
  );

  React.useEffect(() => {
    if (isLibContentOpen) {
      if (selectedDocumentSummary) {
        if (selectedDocument)
          getReferences(
            selectedDocument?.workspace_id,
            selectedDocument.id,
            selectedDocument
          );
      }
    }
  }, [selectedDocumentSummary]);

  const extractedData = (
    Array.isArray(selectedSubSection) ? selectedSubSection : []
  ).map((subSectionId: number) => {
    if (!selectedDocument?.document_section_summaries) {
      return null;
    }

    const section = selectedDocument.document_section_summaries.find(
      (sec: any) =>
        sec.sample_document_sub_sections.some(
          (subSec: any) => subSec.id === subSectionId
        )
    );

    if (!section) {
      return null;
    }

    const subSection = section.sample_document_sub_sections.find(
      (subSec: any) => subSec.id === subSectionId
    );

    if (!subSection) {
      return null;
    }

    const selectedElements = section.sample_document_elements.filter(
      (el: any) => el.document_sub_section_id === subSectionId
    );

    return {
      sectionId: section.id,
      heading: section.heading,
      subSectionId: subSection.id,
      subSectionHeading: subSection.heading,
      selectedElements: selectedElements.map((el: any) => ({
        id: el.document_sub_section_id,
        content: el.content,
        contentType: el.content_type,
        updatedAt: el.updated_at,
      })),
    };
  });

  const finalData = (extractedData || []).filter(Boolean);
  const ddd = useValidateReferences(finalData);

  //action to Attach  Selected subsections
  const handleSave = () => {
    dispatch(setIsLibraryContentAvailable(false));
    if (!parentSubsection) {
      const newComponentOrder =
        Math.min(
          ...parentSection.subsections!.map(function (o) {
            return o.component_order;
          })
        ) - 10;
      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        componentOrder: newComponentOrder !== Infinity ? newComponentOrder : 0,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: selectedSubSection,
        destinationSectionId: props.parentSection!.id,
        // sectionBelowInsert: props.sectionBelowInsert!
      });
      // if it dropped on section having Elements
    } else if (parentSubsection && parentSection) {
      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        componentOrder: parentSubsection.component_order + 10,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: selectedSubSection,
        destinationSectionId: props.parentSection!.id,
        // sectionBelowInsert: props.sectionBelowInsert!
      });
    }
    props.closeDialogHandler();
  };

  const handleCreateCopy = () => {
    // if its is Droped on Empty Section
    dispatch(setIsLibraryContentAvailable(false));
    if (!parentSubsection) {
      const newComponentOrder =
        Math.min(
          ...parentSection.subsections!.map(function (o) {
            return o.component_order;
          })
        ) - 10;
      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        componentOrder: newComponentOrder !== Infinity ? newComponentOrder : 0,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: selectedSubSection,
        destinationSectionId: props.parentSection!.id,
        createCopy: true,
        // sectionBelowInsert: props.sectionBelowInsert!
      });
      // if it dropped on section having Elements
    } else if (parentSubsection && parentSection) {
      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        componentOrder: parentSubsection.component_order + 10,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: selectedSubSection,
        destinationSectionId: props.parentSection!.id,
        createCopy: true,
        // sectionBelowInsert: props.sectionBelowInsert!
      });
    }
    props.closeDialogHandler();
  };

  const handleClickDocumentTemplate = (documentTemplateSummary: any) => {
    if (documentTemplateSummary.document_section_summaries.length == 0) {
      actions.getWorkspaceSummaryDocument({
        documentID: documentTemplateSummary.id,
        workspaceID: documentTemplateSummary.workspace_id,
      });
    }
    setSelectedDocumentSummary(documentTemplateSummary);
  };

  const handleSelectDocumentSectionSummary = (sectionSummary: any) => {
    setSelectedDocumentSectionSummary(sectionSummary);
  };

  const handleFilterOpen = () => {
    SetIsFilterOpen(true);
  };

  const handleFilterclose = () => {
    setSelectedDocumentSummary(null);
    // if date is selected
    if (selectedEndDateParse || selectedStartDateParse) {
      actions.getWorkspaceSummarySearch({
        search: searchString,
        once_off: IsOnceoffDocument,
        allworkspaces: IsOtherWorkSpace,
        purpose: Purpose,
        category: Category,
        allowArchive: IsArchivedShow,
        start_date: dayjs(selectedStartDate).format(
          "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
        ),
        end_date: dayjs(selectedEndDate).format("YYYY-MM-DD HH:mm:ss.SSSSSSZ"),
      });
    } else if (
      searchString ||
      IsOtherWorkSpace ||
      Purpose ||
      Category ||
      !IsOnceoffDocument ||
      IsArchivedShow
    ) {
      actions.getWorkspaceSummarySearch({
        search: searchString,
        once_off: IsOnceoffDocument,
        allworkspaces: IsOtherWorkSpace,
        purpose: Purpose,
        category: Category,
        allowArchive: IsArchivedShow,
      });
    } else {
      // if nothing is Applied in Filter.
      actions.getWorkspaceSummary();
    }
    SetIsFilterOpen(false);
  };
  const handleOptionChangePurpose = (event: any, newValue: any) => {
    SetPurpose(newValue);
  };

  const handleOptionChangeCategory = (event: any, newValue: any) => {
    SetCategory(newValue);
  };
  const handleEndDateChange = (date: any) => {
    const dates = new Date(date);
    const currentTime = new Date();
    dates.setHours(currentTime.getHours());
    dates.setMinutes(currentTime.getMinutes());
    dates.setSeconds(currentTime.getSeconds());
    setSelectedEndDate(dates);
    setSelectedEndDateParse(date);
  };

  const handleStartDateChange = (date: any) => {
    const dates = new Date(date);
    const currentTime = new Date();
    dates.setHours(currentTime.getHours());
    dates.setMinutes(currentTime.getMinutes());
    dates.setSeconds(currentTime.getSeconds());
    setSelectedStartDate(dates);
    setSelectedStartDateParse(date);
  };

  const debouncedHandleChange = (value: string) => {
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null; // Reset timeoutId after clearing
    }
    // Set new timeout
    timeoutId = setTimeout(() => {
      handleInputChange(value);
    }, 1150); // Adjust the delay as needed
  };

  React.useEffect(() => {
    if (selectedDocumentSummary) {
      const summary = workspaceSummaryState.workspaceSummaries?.find(
        (summary) => summary.id === selectedDocumentSummary.id
      );
      if (summary) {
        setSelectedDocumentSummary(summary);
      }
    }
  }, [
    workspaceSummaryState.workspaceSummaries,
    selectedDocumentSummary,
    setSelectedDocumentSummary,
  ]);

  const styles = {
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      color: "black",
      fontSize: "18px",
      fontWeight: "bold",
    },
    spinner: {
      marginRight: "10px",
      color: "black",
    },
  };

  const renderContent = () => {
    if (
      (workspaceSummaryState.status === "idle" ||
        workspaceSummaryState.status === "failed") &&
      workspaceSummaryState.workspaceSummaries?.length === 0
    ) {
      return (
        <div style={styles.loadingContainer}>No data available to display</div>
      );
    }

    if (
      workspaceSummaryState.status === "idle" &&
      workspaceSummaryState.workspaceSummaries?.length !== 0
    ) {
      return workspaceMenuItems;
    }

    return (
      <div style={styles.loadingContainer}>
        <CircularProgress style={styles.spinner} />
        Loading...
      </div>
    );
  };

  const handleInputChange = (value: string) => {
    setSelectedDocumentSummary(null);

    const searchString = value;

    if (searchString !== "") {
      if (selectedStartDateParse) {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
          start_date: dayjs(selectedStartDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
          end_date: dayjs(selectedEndDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
        });
      } else {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
        });
      }
    } else {
      if (selectedStartDateParse) {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
          start_date: dayjs(selectedStartDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
          end_date: dayjs(selectedEndDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
        });
      } else if (
        !IsOnceoffDocument ||
        IsOtherWorkSpace ||
        Purpose ||
        Category ||
        IsArchivedShow
      ) {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
        });
      } else {
        actions.getWorkspaceSummary();
      }
    }
  };

  const handleOtherWorkSpace = (e: any) => {
    SetOtherWorkSpace(e.target.checked);
  };

  const handleIsArchived = (e: any) => {
    SetArchivedShow(e.target.checked);
  };

  const handleOnceoffDocument = (e: any) => {
    SetOnceoffDocument(e.target.checked);
  };

  React.useEffect(() => {
    dispatch(setIsLibraryContentAvailable(true));
  }, []);

  // Left screen of List of Documents
  const workspaceMenuItems = workspaceSummaryState.workspaceSummaries?.map(
    (summary: any) => (
      <MenuItem
        key={summary.id}
        onClick={(e) => handleClickDocumentTemplate(summary)}
        selected={summary === selectedDocumentSummary ? true : false}
      >
        <ListItemIcon>
          {summary === selectedDocumentSummary ? (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          ) : null}
        </ListItemIcon>
        <ListItemText>{summary.name}</ListItemText>
      </MenuItem>
    )
  );

  // right Window Screen(Sections Subsections)
  const documentElementItems =
    selectedDocumentSummary?.document_section_summaries?.map(
      (section: DocumentSectionSummary) => (
        <MyObjectContext.Provider
          value={[selectedSubSection, setSelectedSubSection]}
        >
          <LibraryContentElementSubSection
            key={section.id}
            selectElementHandler={handleSelectDocumentSectionSummary}
            documentTemplateSummary={selectedDocumentSummary}
            documentSectionSummary={section}
            isSelected={true}
            organizationId={props.organizationId}
            workspaceId={props.workspaceId}
            viewPreferences={props.viewPreferences}
          />
        </MyObjectContext.Provider>
      )
    );
  return (
    <>
      <Dialog
        open={IsLibraryOpen}
        onClose={(e) => {
          // SetIsLibarayOpen(false);
          console.log("closing");
          props.closeDialogHandler();
          dispatch(setIsLibraryContentAvailable(false));
        }}
        fullWidth={true}
        maxWidth={"lg"}
        scroll="paper"
      >
        <DialogTitle>
          <MenuBookIcon style={{ marginRight: "20px" }} />
          Available Library Content
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* workspaceSummaryState.status === "loading" ? (
          <CircularProgress></CircularProgress>
        ) : null */}
          {workspaceSummaryState.error !== undefined ? (
            <Alert
              severity="error"
              onClose={() => {
                dispatch(setError(undefined));
              }}
            >
              {workspaceSummaryState.error}
            </Alert>
          ) : null}
          {ddd.error && (
            <Alert severity="warning">
              {ddd.error}
              {"\n"}
            </Alert>
          )}

          {
            <Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography marginTop={2} style={{ float: "left" }}>
                      Published Documents
                    </Typography>
                  </Grid>
                  <Grid item xs={7} textAlign="center">
                    <div style={{ verticalAlign: "top" }}>
                      <Typography marginTop={2} style={{ float: "left" }}>
                        Available Library Content
                      </Typography>
                      <Input
                        placeholder={"Search"}
                        style={{
                          float: "right",
                          width: "320px",
                          padding: "8px 0 2px",
                        }}
                        onChange={(event) => {
                          setSearchString(event.target.value);
                          debouncedHandleChange(event.target.value);
                        }}
                      ></Input>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    textAlign="center"
                    style={{ padding: "28px" }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleFilterOpen}
                    >
                      <FilterListIcon fontSize="small" color="primary" />
                    </Button>
                  </Grid>
                  {/* <FilterListIcon
                    onclick={handleFilterOpen}
                    fontSize="small"
                    color="primary"
                  /> */}
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Paper
                      sx={{
                        // mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 500,
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                      variant="outlined"
                    >
                      <List>
                        {renderContent()}

                        {/* {workspaceMenuItems} */}
                      </List>
                    </Paper>
                  </Grid>
                  <Grid item xs={8}>
                    <Paper
                      sx={{
                        // mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 500,
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                      variant="outlined"
                    >
                      <List>{documentElementItems}</List>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              !selectedDocumentSummary || !selectedDocumentSectionSummary
            }
          >
            Insert
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCopy}
            disabled={
              !selectedDocumentSummary ||
              !selectedDocumentSectionSummary ||
              selectedSubSection.length == 0
            }
          >
            Create Copy
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={IsFilterOpen} onClose={handleFilterclose}>
        {/* <ExpandRoundedIcon/>   */}
        <DialogTitle style={{ paddingBottom: "0px" }}>
          Search Filter
        </DialogTitle>

        <Box sx={{ p: 2 }}>
          <Box sx={{ padding: 0 }}>
            <Typography
              sx={{
                display: "inline",
                marginTop: "0px",
                marginBottom: "15px",
                padding: 0,
              }}
            >
              Include content from once-off Documents?
            </Typography>
            <Checkbox
              checked={IsOnceoffDocument}
              onChange={(event) => handleOnceoffDocument(event)}
            />
          </Box>

          <Box sx={{ padding: 0 }}>
            <Typography
              sx={{
                display: "inline",
                marginTop: "15px",
                marginBottom: "15px",
                padding: 0,
              }}
            >
              Search accross workspaces?
            </Typography>
            <Checkbox
              checked={IsOtherWorkSpace}
              onChange={(event) => handleOtherWorkSpace(event)}
            />
          </Box>
          <Box sx={{ padding: 0 }}>
            <Typography
              sx={{
                display: "inline",
                marginTop: "15px",
                marginBottom: "15px",
                padding: 0,
              }}
            >
              Allow archived documents?
            </Typography>
            <Checkbox
              checked={IsArchivedShow}
              onChange={(event) => handleIsArchived(event)}
            />
          </Box>

          <Autocomplete
            id="free-solo-CategoryList"
            freeSolo
            value={Category}
            options={categoriesList}
            onChange={handleOptionChangeCategory}
            renderInput={(params) => (
              <TextField {...params} label="Select Category" />
            )}
          />
          <Autocomplete
            sx={{ marginTop: 1 }}
            id="free-solo-PurposeList"
            freeSolo
            value={Purpose}
            options={purposesList}
            onChange={handleOptionChangePurpose}
            renderInput={(params) => (
              <TextField {...params} label={`Select ${SUB_CATEGORY}`} />
            )}
          />
          <Box sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <DatePicker
                sx={{ marginRight: 1 }}
                label="Published Start Date"
                value={selectedStartDateParse}
                shouldDisableDate={disableFutureDates}
                onChange={handleStartDateChange}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <DatePicker
                label="Published End Date"
                value={selectedEndDateParse}
                shouldDisableDate={disableFutureDates}
                onChange={(e) => {
                  handleEndDateChange(e);
                }}
              />
            </LocalizationProvider>
          </Box>

          {/* <DesktopDatePicker

          /> */}

          <DialogActions>
            <Button
              style={{ border: "1px solid" }}
              onClick={() => SetIsFilterOpen(false)}
            >
              Cancel
            </Button>
            <Button style={{ border: "1px solid" }} onClick={handleReset}>
              Reset
            </Button>
            <Button style={{ border: "1px solid" }} onClick={handleFilterclose}>
              Apply
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
