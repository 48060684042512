import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function DebugID(props: {
  name: string;
  id: number;
  componentOrder: number;
  showCompOrder?: boolean;
  ssid?:number
}) {
  return (
    <Box>
      <Typography>
        <i>
          {props.showCompOrder ? (
            <>
              <b style={{fontSize:"14px"}}>{"Subsection"} ID: {props.ssid}</b> 
            </>
          ) : (
            <>
              <b>{props.name}</b> ID: {props.id}; Component order:{" "}
              {props.componentOrder}
            </>
          )}
        </i>
      </Typography>
    </Box>
  );
}
