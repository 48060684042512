type SanitizedNumbering = {
  type: string;
  id: number;
  idstr: string;
  number: string;
  numberhash: string;
};

export default function validateReferences(
  data: any,
  sanitizedNumberings: SanitizedNumbering[]
): boolean {
  // console.log("DATA", data);
  const validElementIds = new Set(
    sanitizedNumberings
      .filter((item) => item.type === "element")
      .map((item) => item.id)
  );
  const validSectionIds = new Set(
    sanitizedNumberings
      .filter((item) => item.type === "section")
      .map((item) => item.id)
  );
  const validTableIds = new Set(
    sanitizedNumberings
      .filter((item) => item.type === "table" || item.type === "image")
      .map((item) => item.id)
  );

  const refRegex = /##(e|s|et)(\d+)##/g;

  const validateContent = (content: string): boolean => {
    let match;
    let hasReferences = false;

    while ((match = refRegex.exec(content)) !== null) {
      hasReferences = true;
      const refType = match[1];
      const refId = parseInt(match[2], 10);

      if (
        (refType === "e" && !validElementIds.has(refId)) ||
        (refType === "s" && !validSectionIds.has(refId)) ||
        (refType === "et" && !validTableIds.has(refId))
      ) {
        console.log(`Missing or invalid reference: ##${refType}${refId}##`);
        return true;
      }
    }

    return hasReferences;
  };

  const { subsections, section } = data;

  if (subsections) {
    if (subsections.elements[0]) {
      for (const element of subsections.elements) {
        if (validateContent(element.content)) {
          return true;
        }
      }
    }
  }

  if (section && section.heading) {
    if (validateContent(section.heading || section.content)) {
      return true;
    }
  }

  return false;
}
