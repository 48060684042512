import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useActions from "../../app/hooks";
import { useDispatch } from "react-redux";
import { setFromReviewComments } from "../comments/commentingDetailsSlice";

/**
 * The organization and workspace menu.
 *
 * @export
 * @param {{user: User | null, organizations: Organization[] | null, workspaces: Workspace[] | null}} props
 * @return {*}
 */
export function CommentsApprovalMenu(props: {
  documentTemplates: DocumentTemplate[];
}) {
  const actions = useActions();
  const dispatch = useDispatch<any>();
  const [anchorElOrg, setAnchorElOrg] = React.useState<null | HTMLElement>(
    null
  );

  const handleClickDocumentTemplate = (dt: DocumentTemplate) => {
    dispatch(setFromReviewComments(true));
    actions.setOpenApprovalDocumentTemplate(dt);
  };

  const handleOpenApprovalMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrg(event.currentTarget);
  };

  const handleCloseApprovalMenu = () => {
    setAnchorElOrg(null);
  };

  const organizationMenuItems = props.documentTemplates.map((dt_) => (
    <MenuItem
      key={"menu_" + dt_.id}
      onClick={(e) => handleClickDocumentTemplate(dt_)}
    >
      <ListItemText>{dt_.name}</ListItemText>
    </MenuItem>
  ));

  return (
    <Box sx={{ flexGrow: 0, marginLeft: "10px" }}>
      <Tooltip title="Pending document(s) for commenting">
        <span>
          <Button
            color="info"
            variant="contained"
            onClick={handleOpenApprovalMenu}
            endIcon={
              anchorElOrg == null ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )
            }
          >
            Comments #{props.documentTemplates?.length}
          </Button>
        </span>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElOrg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElOrg)}
        onClose={handleCloseApprovalMenu}
      >
        {organizationMenuItems}
      </Menu>
    </Box>
  );
}
