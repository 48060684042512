import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FileUpload, FileUploadProps } from "../../shared/FileUpload";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  LinearProgress,
  Typography,
} from "@mui/material";
import { SUB_CATEGORY } from "../../../globals";
import {
  selectAuth,
  selectResponseError,
  selectUser,
  setErrorMsg,
} from "../../auth/authSlice";
import { ExcelFileUpload } from "../../shared/ExcelFileUpload";
import { apiGetCategory } from "../../../services/documentTemplatesAPI";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ImportTemplateFromDocForm(props: {
  open: boolean;
  closeDialogHandler: any;
}) {
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);

  const default_organization_id =
    auth.user?.default_organization_id || user?.default_orgainization_id;

  const default_workspace_id =
    auth.user?.default_workspace_id || user?.default_workspace_id;
  const actions = useActions();
  const dispatch = useAppDispatch();
  const [file, setFile] = React.useState<File | null>(null);
  const [name, setName] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [purposeList, setPurposeList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [invalidFileType, setInvalidFileType] = React.useState(false);
  const [isOnceOff, setIsonceOff] = React.useState(false);
  const selectErrorMsgState = useAppSelector(selectResponseError);

  const fileUploadProp: FileUploadProps = {
    accept: ".doc, .docx",
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        const droppedFile = event.target.files[0];
        if (
          droppedFile &&
          (droppedFile.name.endsWith(".doc") ||
            droppedFile.name.endsWith(".docx"))
        ) {
          setFile(droppedFile);
          setInvalidFileType(false);
        } else {
          setInvalidFileType(true);
          setFile(null);
        }
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      const droppedFile = event.dataTransfer.files[0];
      if (
        droppedFile &&
        (droppedFile.name.endsWith(".doc") ||
          droppedFile.name.endsWith(".docx"))
      ) {
        setFile(droppedFile);
        setInvalidFileType(false);
      } else {
        setFile(null);
      }
    },
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
    // if error already exist clear the error state
    if (selectErrorMsgState.status === "failed") {
      dispatch(
        setErrorMsg({
          status: "idle",
          errorMsg: undefined,
          errorType: undefined,
        })
      );
    }
  };

  const handleCategoryOptionChange = (event: any, newValue: any) => {
    setCategory(newValue);
  };

  function handleCategoryChange(e: any) {
    setCategory(e.target.value);
  }

  const handlePurposeChange = (e: any) => {
    setPurpose(e.target.value);
  };

  const handleOptionChangePurpose = (event: any, newValue: any) => {
    setPurpose(newValue);
  };

  React.useEffect(() => {
    if (
      selectErrorMsgState.status === "success" &&
      selectErrorMsgState.errorType === "importDocumentTemplateFromDoc"
    ) {
      dispatch(
        setErrorMsg({
          status: "idle",
          errorMsg: undefined,
          errorType: undefined,
        })
      );
      props.closeDialogHandler();
    }
  }, [selectErrorMsgState]);

  const handleClose = () => {
    dispatch(
      setErrorMsg({
        status: "idle",
        errorMsg: undefined,
        errorType: undefined,
      })
    );
    props.closeDialogHandler();
  };

  const handleUploadTemplate = () => {
    if (file != null)
      actions.importDocumentTemplateFromDoc({
        file: file,
        name: name,
        category: category,
        subCategory: purpose,
        isOnceOff: isOnceOff,
      });
    // props.closeDialogHandler();
  };
  React.useEffect(() => {
    async function getList() {
      const response = await apiGetCategory(
        default_organization_id!,
        default_workspace_id!
      );
      setCategoryList(response.category_list);
      setPurposeList(response.purpose_list);
    }
    try {
      if (props.open) {
        getList();
      }
    } catch (error) {
      console.error(
        "Error While calling get Organization, Workspace, getList",
        error
      );
    }
  }, [props.open]);

  const InstructionsAccordion = (
    <>
      <Box sx={{ marginTop: 1, px: 1 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ textAlign: "justify" }}>
              Please upload a MS Word file. For best results ensure that styles
              are applied to headings (Style 1 for level 1, Style 2 for level 2,
              etc.) Images, tables and bulleted lists will be ignored, but text
              content inside tables and numbered lists will be imported.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );

  return (
    <Dialog fullWidth open={true} onClose={handleClose}>
      <DialogTitle>Import Template From Existing Document(Doc)</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          required
          onChange={handleNameChange}
          error={
            name === "" ||
            (selectErrorMsgState.status === "failed" &&
              selectErrorMsgState.errorType === "importDocumentTemplateFromDoc")
          }
          value={name}
          helperText={
            name === ""
              ? "Name cannot be empty"
              : selectErrorMsgState.status === "failed" &&
                selectErrorMsgState.errorType ===
                  "importDocumentTemplateFromDoc"
              ? "This name already exits!"
              : null
          }
        />

        <Autocomplete
          id="auto"
          freeSolo
          autoSelect
          options={categoryList}
          onChange={handleCategoryOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="category"
              label="Category"
              fullWidth
              required
              onChange={handleCategoryChange}
              error={category === ""}
              variant="standard"
            />
          )}
        />

        <Autocomplete
          id="autoc"
          freeSolo
          autoSelect
          options={purposeList}
          onChange={handleOptionChangePurpose}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="purpose"
              label={SUB_CATEGORY}
              fullWidth
              required
              onChange={handlePurposeChange}
              error={purpose === ""}
              // error={purposeError!==""}
              variant="standard"
            />
          )}
        />

        <Box>
          <Typography
            style={{ paddingTop: "0px" }}
            sx={{
              display: "inline",
              marginTop: "0px",
              marginBottom: "15px",
            }}
          >
            Create once-off Document?
          </Typography>
          <Checkbox
            checked={isOnceOff}
            onChange={(event) => setIsonceOff(!isOnceOff)}
          />
        </Box>

        {InstructionsAccordion}

        <Box sx={{ padding: 1 }}>
          <ExcelFileUpload {...fileUploadProp} />
          {selectErrorMsgState.status === "loading" &&
            selectErrorMsgState.errorType ===
              "importDocumentTemplateFromDoc" && <LinearProgress />}
        </Box>

        {invalidFileType ? (
          <Alert severity="error" sx={{ fontWeight: "bold" }}>
            Please upload a valid Doc file.
          </Alert>
        ) : null}

        {file !== null && (
          <Typography
            sx={{
              p: 2,
              fontWeight: "bold",
              color: "green",
            }}
          >
            {" "}
            File Name: {file.name}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={(e) => {
            handleUploadTemplate();
          }}
          disabled={
            file == null ||
            name === "" ||
            purpose === "" ||
            category === "" ||
            ((selectErrorMsgState.status === "loading" ||
              selectErrorMsgState.status === "failed") &&
              selectErrorMsgState.errorType === "importDocumentTemplateFromDoc")
          }
        >
          create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
