import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Organization } from "../../types/User";
import DetailCard from "./DetailCard";
import {
  getActionText,
  monthlyPaymentPlans,
  monthlyPlansTrail,
  yearlyPaymentPlans,
  yearlyPlansTrails,
} from "./utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {
  apiActivePlan,
  apiCancelSubscription,
  apiSubscribePlan,
  apiTransactionHistory,
} from "../../services/subscriptionAPI";
import TransactionHistory from "./HistoryTable";
import ConfirmDialog from "./ConfirmDialog";
import { host } from "src/services/client";

export default function PaymentModal(props: {
  closeDialogHandler: any;
  organization: Organization;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [userEmail, setUserEmail] = useState("");
  const [subscribedPlan, setSubscribedPlan] = useState<undefined | string>(
    undefined
  );
  const [subscribedSubs, setSubscribedSubs] = useState<undefined | string>(
    undefined
  );
  const [paymentStep, setPaymentStep] = useState<1 | 2 | 3>(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState({
    planID: null,
    planActions: {
      text: "Subscribe",
      color: "#4C33FF",
      action: "subscribe",
      title: "",
      content: "",
      showDialog: false,
    },
  });
  const [paymentSelection, setPaymentSelection] = useState<
    "monthly" | "yearly" | "Null"
  >("Null");

  const isDevelopment = () => {
    const hostUrl: any = host;
    return hostUrl === "https://tapi.nomiadocs.com/" ? true : false;
  };
  console.log("🚀 ~ isDevelopment:", isDevelopment);

  const monthlyPlans = isDevelopment()
    ? monthlyPlansTrail
    : monthlyPaymentPlans;
  const yearlyPlans = isDevelopment() ? yearlyPlansTrails : yearlyPaymentPlans;

  // uncomment this for production payment plans
  // const monthlyPlans = monthlyPaymentPlans; // production plans
  // const yearlyPlans = yearlyPaymentPlans; // dev plans

  // dev payment plans
  // const monthlyPlans = monthlyPlansTrail; // dev plans
  // const yearlyPlans = yearlyPlansTrails; // dev plans

  const paymentPlanDetails =
    monthlyPlans.find((plan) => plan.planId === subscribedPlan) ||
    yearlyPlans.find((plan) => plan.planId === subscribedPlan);

  useEffect(() => {
    getActiveSubscription(props.organization.id);
    getTransactionHistory(props.organization.id);
  }, []);

  const paymentUI = () => {
    return (
      <Box
        sx={{
          // width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },

          maxWidth: "500px",
          py: 4,
          px: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          boxShadow: 3,
          borderRadius: 2,
          margin: "0 auto",
          mt: 1,
        }}
      >
        <TextField
          value={userEmail}
          onChange={handleEditUserEmail}
          label={"Enter email"}
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
        />
        <Button
          variant="contained"
          onClick={confirmPayment}
          sx={{
            borderRadius: 8,
            px: 4,
            py: 1.5,
            backgroundColor: "#4C33FF",
            "&:hover": { backgroundColor: "#3a27cc" },
          }}
          disabled={userEmail === ""}
        >
          Subscribe
        </Button>
      </Box>
    );
  };

  const content = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{ px: 1, mb: 2 }}
          onClick={() => setPaymentSelection("monthly")}
        >
          <DetailCard
            title="Monthly"
            content="Starting from ZAR 200"
            paymentPlan={paymentPlan.planID}
            subscribedPlan={subscribedPlan}
            setPaymentPlan={(e: any) => {
              setPaymentPlan({
                planID: e,
                planActions: getActionText(paymentPlanDetails?.id, e),
              });
            }}
            plans={monthlyPlans}
          />
          {paymentPlan.planID != null && paymentSelection === "monthly" && (
            <Button
              onClick={handleButton}
              sx={{
                mt: 2,
                borderRadius: 8,
                backgroundColor: paymentPlan.planActions.color,
                px: 4,
                py: 1.5,
                boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
              }}
              variant="contained"
              disabled={paymentPlan.planID == null}
            >
              {paymentPlan.planActions.text}
            </Button>
          )}
        </Box>
        <Box
          sx={{ px: 1, mb: 2 }}
          onClick={() => setPaymentSelection("yearly")}
        >
          <DetailCard
            title="Yearly"
            content="Starting from ZAR 2000"
            paymentPlan={paymentPlan.planID}
            plans={yearlyPlans}
            subscribedPlan={subscribedPlan}
            setPaymentPlan={(e: any) => {
              setPaymentPlan({
                planID: e,
                planActions: getActionText(paymentPlanDetails?.id, e),
              });
            }}
          />
          {paymentPlan.planID != null && paymentSelection === "yearly" && (
            <Button
              onClick={handleButton}
              sx={{
                mt: 2,
                borderRadius: 8,
                backgroundColor: paymentPlan.planActions.color,
                px: 4,
                py: 1.5,
                boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
              }}
              variant="contained"
              disabled={paymentPlan.planID == null}
            >
              {paymentPlan.planActions.text}
            </Button>
          )}
        </Box>
        <Box sx={{ px: 1, mb: 2 }}>
          <Typography variant="body2" color="textSecondary">
            If you require more credits than those listed per month or annually,
            please reach out to our support team at{" "}
            <a href="mailto:support@nomiadocs.com">help@nomiadocs.com</a>.
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleButton = () => {
    if (paymentPlan.planActions.action === "subscribe") {
      setPaymentStep(2);
    } else if (paymentPlan.planActions.action === "unsubscribe") {
      setShowConfirm(true);
    } else if (
      paymentPlan.planActions.action === "upgrade" ||
      paymentPlan.planActions.action === "downgrade"
    ) {
      setShowConfirm(true);
    }
  };

  const cancelSubscription = async (planID: string | undefined) => {
    try {
      if (subscribedSubs) {
        const response = await apiCancelSubscription(subscribedSubs);
        window.open(response.data.link, "_blank");
        props.closeDialogHandler();
      }
    } catch (e) {
      console.log("unable to cancel current Subscription plan", e);
    }
  };

  const confirmPayment = async () => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!userEmail.match(validRegex)) {
      enqueueSnackbar("Invalid Email Address", { variant: "error" });
      return;
    }

    const paymentPlanDetails =
      monthlyPlans.find((plan) => plan.id === paymentPlan.planID) ||
      yearlyPlans.find((plan) => plan.id === paymentPlan.planID);
    if (paymentPlanDetails?.planId) {
      const response = await apiSubscribePlan(
        props.organization.id,
        paymentSelection,
        paymentPlanDetails.planId,
        userEmail
      );
      window.open(response.data.transaction, "_blank");
      props.closeDialogHandler();
    }
  };

  const getActiveSubscription = async (orgId: number) => {
    try {
      const response = await apiActivePlan(orgId);
      setSubscribedPlan(response.data.subscriptions[0].plan_id);
      setSubscribedSubs(response.data.subscriptions[0].subscription_code);
    } catch (e) {
      console.error("unable to fetch Transaction History", e);
    }
  };

  const getTransactionHistory = async (orgId: number) => {
    try {
      const responseHistory = await apiTransactionHistory(orgId);
      setTransactionHistory(responseHistory.data.subscriptions);
    } catch (e) {
      console.error("unable to fetch Transaction History", e);
    }
  };

  const handleEditUserEmail = (e: any) => {
    setUserEmail(e.target.value);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={paymentStep === 1 ? "lg" : "sm"}
        scroll="paper"
        open={true}
        onClose={() => props.closeDialogHandler()}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Typography sx={{ fontSize: "18pt", fontWeight: "bold" }}>
            {paymentStep !== 1 && (
              <IconButton
                onClick={() => setPaymentStep(1)}
                sx={{ color: "black", mr: "auto" }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
            {paymentStep == 1
              ? "Subscription"
              : "Please enter your billing email"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => props.closeDialogHandler()}
            sx={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card
            sx={{
              width: "100%",
              height: "auto",
              textAlign: "center",
              py: 4,
              px: 3,
              backgroundColor: "#fafafa",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              position: "relative",
            }}
          >
            {subscribedPlan && (
              <Box
                sx={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => cancelSubscription(subscribedPlan)}
                  sx={{
                    borderRadius: 8,
                    px: 4,
                    py: 1.5,
                    backgroundColor: "#4C33FF",
                    "&:hover": { backgroundColor: "#3a27cc" },
                  }}
                >
                  Update card
                </Button>
              </Box>
            )}

            {paymentStep === 1 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 3,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18pt",
                    textAlign: "center",
                    flex: "1",
                  }}
                >
                  {paymentStep === 1 && "Choose the best plan for you"}
                </Typography>
              </Box>
            )}
            {paymentStep === 1 && content()}
            {paymentStep === 2 && paymentUI()}
          </Card>
          {paymentStep === 1 && transactionHistory.length !== 0 && (
            <TransactionHistory transactionDetails={transactionHistory} />
          )}
        </DialogContent>
      </Dialog>
      {showConfirm && (
        <ConfirmDialog
          title={paymentPlan.planActions.title}
          content={paymentPlan.planActions.content}
          handleClose={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => cancelSubscription(subscribedPlan)}
        />
      )}
    </>
  );
}
