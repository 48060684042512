import React from "react";
import {
  ContentType,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../../types/DocumentTemplate";
import { attachedSubsectionElement } from "../document/utils";

function isValidHeading(
  subHeadingLevel: number,
  currentTextLevel: number
): number {
  if (currentTextLevel > 6) {
    return (currentTextLevel = 6);
  } else if (currentTextLevel <= subHeadingLevel) {
    return (currentTextLevel = subHeadingLevel + 1);
  } else {
    return currentTextLevel;
  }
}

function correctTextLevel(
  subHeadingLevel: number,
  prevTextLevel: number,
  currentTextLevel: number,
  previousNewLevel: number,
  currentElementId?: any
): number {
  // Ensure the currentTextLevel is greater than the subHeadingLevel
  if (subHeadingLevel === 6) {
    currentTextLevel = 6;
    return currentTextLevel;
  }

  if (currentTextLevel <= subHeadingLevel) {
    currentTextLevel = subHeadingLevel + 1;
    return isValidHeading(subHeadingLevel, currentTextLevel);
  }

  if (currentTextLevel === prevTextLevel) {
    currentTextLevel = previousNewLevel;
    return isValidHeading(subHeadingLevel, currentTextLevel);
  }

  if (currentTextLevel - prevTextLevel >= 1) {
    currentTextLevel = previousNewLevel + 1;
    return isValidHeading(subHeadingLevel, currentTextLevel);
  }

  if (currentTextLevel - prevTextLevel < 0) {
    const difference = currentTextLevel - prevTextLevel;
    currentTextLevel = previousNewLevel + difference;
    return isValidHeading(subHeadingLevel, currentTextLevel);
  }

  // Limit the currentTextLevel to be at most 1 level greater than the previous Text level
  if (currentTextLevel > prevTextLevel + 1) {
    currentTextLevel = previousNewLevel + 1;
    return isValidHeading(subHeadingLevel, currentTextLevel);
  }

  return isValidHeading(subHeadingLevel, currentTextLevel);
}

export function fixHeadingLevelsOfCompleteSection(
  documentTemplate: DocumentTemplate,
  documentSection: DocumentSection
) {
  const headinglevelsToBeUpdated: any[] = [];
  const textWithOutHeadingToBeUpdated: any[] = [];
  const subHeadingLevelsToBeUpdated: any[] = [];

  try {
    // find the selected SubSection
    const subHeadings = findSubheadingsInSection(
      documentTemplate,
      documentSection
    );

    const textSubheading = findTextElementWithoutParentSubHeading(
      documentTemplate,
      documentSection
    );

    // update Text Without Subheading
    if (textSubheading.length > 0) {
      textWithOutHeadingToBeUpdated.push({
        subSectionID: textSubheading[0].SubsectionID,
        ElementID: textSubheading[0].ElementID,
        newHeadingLevel: 2,
      });
      // textSubheading[0].heading_Level = 2;

      for (let i = 1; i < textSubheading.length; i++) {
        const currentTextElement = textSubheading[i];
        const previousTextElement = textSubheading[i - 1];

        textWithOutHeadingToBeUpdated.push({
          subSectionID: currentTextElement.SubsectionID,
          ElementID: currentTextElement.ElementID,
          newHeadingLevel: correctTextLevel(
            1,
            previousTextElement.heading_Level,
            currentTextElement.heading_Level,
            textWithOutHeadingToBeUpdated[i - 1].newHeadingLevel
          ),
        });
      }
    }

    if (subHeadings.length > 0) {
      subHeadingLevelsToBeUpdated.push({
        subSectionID: subHeadings[0].SubsectionID,
        ElementID: subHeadings[0].ElementID,
        newHeadingLevel: 2,
      });
      subHeadings[0].heading_Level = 2;

      for (let i = 1; i < subHeadings.length; i++) {
        const currentTextElement = subHeadings[i];
        const previousTextElement = subHeadings[i - 1];

        subHeadingLevelsToBeUpdated.push({
          subSectionID: currentTextElement.SubsectionID,
          // subsectionComponentOrder: subHeadings[i].component_order
          ElementID: currentTextElement.ElementID,
          newHeadingLevel: correctTextLevel(
            1,
            previousTextElement.heading_Level,
            currentTextElement.heading_Level,
            subHeadingLevelsToBeUpdated[i - 1].newHeadingLevel
          ),
        });
      }
    }

    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === documentSection.id) {
        // Check if Subsections Exist
        if (
          currentSection.subsections &&
          currentSection.subsections.length > 0
        ) {
          /// loop through the subsection to find Elements
          currentSection.subsections.forEach((subSection) => {
            /// implementing the logic: if a heading is found, find its children (text elements) and update their levels.

            if (
              subSection.elements &&
              subSection.elements.length > 0 &&
              subSection.elements[0].content_type === "HEADING" &&
              subSection.pending_deleted_at === null
            ) {
              // const aboveheadingLevel = subSection.elements[0].heading_level;
              const aboveheadingLevel = subHeadingLevelsToBeUpdated.find(
                (subHeading) =>
                  // subHeading.ElementID === subSection!.elements![0].id
                  subHeading.ElementID ===
                  attachedSubsectionElement(subSection).id
              ).newHeadingLevel;
              /// find all the child text elements
              const childTextElements = findTextElementBelowSubHeading(
                documentTemplate,
                currentSection,
                subSection
              );

              if (childTextElements.length > 0) {
                // set the first text element = SubHeading Level (headingLevel + 1)
                headinglevelsToBeUpdated.push({
                  subSectionID: childTextElements[0].SubsectionID,
                  ElementID: childTextElements[0].ElementID,
                  newHeadingLevel: aboveheadingLevel + 1,
                });
                // childTextElements[0].heading_Level = aboveheadingLevel + 1;

                // Update the rest of the text elements
                for (let i = 1; i < childTextElements.length; i++) {
                  const currentTextElement = childTextElements[i];
                  const previousTextElement = childTextElements[i - 1];
                  const previousTextUpdatedLevel =
                    headinglevelsToBeUpdated.find(
                      (_temp: any) =>
                        previousTextElement.SubsectionID == _temp.subSectionID
                    ).newHeadingLevel || previousTextElement.heading_Level;

                  // console for debugging
                  // console.log("in loop" , correctTextLevel(
                  //   aboveheadingLevel,
                  //   previousTextElement.heading_Level,
                  //   currentTextElement.heading_Level,
                  //   headinglevelsToBeUpdated[i - 1].newHeadingLevel
                  // ), "currentTextElement" ,currentTextElement ,"previousTextElement " ,  previousTextElement  ,  "headinglevelsToBeUpdated" , headinglevelsToBeUpdated , "index " , i ,"value" ,headinglevelsToBeUpdated[i - 1] )

                  headinglevelsToBeUpdated.push({
                    subSectionID: currentTextElement.SubsectionID,
                    ElementID: currentTextElement.ElementID,
                    newHeadingLevel: correctTextLevel(
                      aboveheadingLevel,
                      previousTextElement.heading_Level,
                      currentTextElement.heading_Level,
                      // headinglevelsToBeUpdated[i - 1].newHeadingLevel,
                      headinglevelsToBeUpdated.find(
                        (_temp: any) =>
                          previousTextElement.SubsectionID == _temp.subSectionID
                      ).newHeadingLevel
                        ? headinglevelsToBeUpdated.find(
                            (_temp: any) =>
                              previousTextElement.SubsectionID ==
                              _temp.subSectionID
                          ).newHeadingLevel
                        : previousTextElement.heading_Level,
                      currentTextElement.ElementID
                    ),
                  });
                }
              }
            }
          });
        }
      }
    });
  } catch (error) {
    console.error("Unable to Fix Elements Heading Level of Section: ", error);
  }
  const allElementsToBeUdpated: any[] = [
    ...headinglevelsToBeUpdated,
    ...textWithOutHeadingToBeUpdated,
    ...subHeadingLevelsToBeUpdated,
  ];

  return allElementsToBeUdpated;
}

// export function fixHeadingLevelsOfCompleteSection(
//   documentTemplate: DocumentTemplate,
//   documentSection: DocumentSection
// ) {
//   const headinglevelsToBeUpdated: any = [];
//   // const [headinglevelsToBeUpdated, setHeadinglevelsToBeUpdated] = React.useState<HeadingLevelUpdate[]>([]);
//   try {
//     // find the selected SubSection
//     documentTemplate.sections?.map((currentSection, index) => {
//       if (currentSection.id === documentSection.id) {
//         // Check if Subsections Exist
//         if (currentSection.subsections!.length > 0) {
//           /// loop through the subsection to find Elements
//           currentSection.subsections?.map((subSection, subSectionIndex) => {

//             /// implementing the logic : if heading found ,  find the child of that perticualr sub heading and update the child if it is Text Element and Level greater than 1
//             if (subSection!.elements![0].content_type === "HEADING") {
//               const aboveheadingLevel = subSection!.elements![0].heading_level;
//               /// find the all the child text Element
//               const childTextElements = findTextElementBelowSubHeading(
//                 documentTemplate,
//                 currentSection,
//                 subSection
//               );
//               /// traverse through the childs
//               // set the first text Element  = SubHeading Level (headingLevel + 1)
//               if (childTextElements.length) {
//                 headinglevelsToBeUpdated.push({
//                   parentSubHeadingLevel: aboveheadingLevel,
//                   subSectionID: childTextElements[0].SubsectionID,
//                   ElementID: childTextElements[0].ElementID,
//                   previousHeadingLevel: childTextElements[0].heading_Level,
//                   newHeadingLevel: (aboveheadingLevel + 1) as number,
//                 });
//               }

//               for (let i = 1; i < childTextElements.length; i++) {
//                 const currentTextElement = childTextElements[i];
//                 const previousTextElement = childTextElements[i - 1];
//                 if (
//                   previousTextElement.heading_Level ===
//                   currentTextElement.heading_Level
//                 ) {
//                   console.log(
//                     "the Equal Condition",
//                     previousTextElement,
//                     currentTextElement
//                   );

//                   headinglevelsToBeUpdated.push({
//                     parentSubHeadingLevel: aboveheadingLevel,
//                     subSectionID: childTextElements[i].SubsectionID,
//                     ElementID: childTextElements[i].ElementID,
//                     previousHeadingLevel: childTextElements[i].heading_Level,
//                     newHeadingLevel: headinglevelsToBeUpdated[i - 1]
//                       .newHeadingLevel as number,
//                   });
//                 }
//                 // if  the text element level s greater than the parent subHeading
//                 else if (
//                   currentTextElement.heading_Level <= aboveheadingLevel
//                 ) {
//                   console.log(
//                     "the <=heading Condition",
//                     previousTextElement,
//                     currentTextElement
//                   );

//                   headinglevelsToBeUpdated.push({
//                     parentSubHeadingLevel: aboveheadingLevel,
//                     subSectionID: childTextElements[i].SubsectionID,
//                     ElementID: childTextElements[i].ElementID,
//                     previousHeadingLevel: childTextElements[i].heading_Level,
//                     newHeadingLevel: (aboveheadingLevel + 1) as number,
//                   });
//                 }
//                 // check if the current is child of previous Text Element
//                 else if (
//                   currentTextElement.heading_Level -
//                     previousTextElement.heading_Level >=
//                   1
//                 ) {
//                   console.log(
//                     "the >=1 condition",
//                     previousTextElement,
//                     currentTextElement
//                   );
//                   headinglevelsToBeUpdated.push({
//                     parentSubHeadingLevel: aboveheadingLevel,
//                     subSectionID: childTextElements[i].SubsectionID,
//                     ElementID: childTextElements[i].ElementID,
//                     previousHeadingLevel: childTextElements[i].heading_Level,
//                     newHeadingLevel: (headinglevelsToBeUpdated[i - 1].newHeadingLevel + 1) as number,
//                   });
//                 }
//                 // check if the current element is not the child of its above text Element
//                 else if (
//                   currentTextElement.heading_Level -
//                     previousTextElement.heading_Level <
//                   0
//                 ) {
//                   console.log(
//                     "the <0 condition",
//                     previousTextElement,
//                     currentTextElement
//                   );
//                   const differnece =
//                     currentTextElement.heading_Level -
//                     previousTextElement.heading_Level;
//                   const newLevel =
//                     headinglevelsToBeUpdated[i - 1].newHeadingLevel -differnece;
//                   headinglevelsToBeUpdated.push({
//                     parentSubHeadingLevel: aboveheadingLevel,
//                     subSectionID: childTextElements[i].SubsectionID,
//                     ElementID: childTextElements[i].ElementID,
//                     previousHeadingLevel: childTextElements[i].heading_Level,
//                     newHeadingLevel:
//                       newLevel >= aboveheadingLevel
//                         ? aboveheadingLevel + 1
//                         : newLevel,
//                   });
//                 }
//               }
//             }
//           });
//         }
//       }
//     });
//   } catch (error) {
//     console.error("Unable to Fix Elements Heading Level of Section: ", error);
//     return headinglevelsToBeUpdated;
//   }

//   return headinglevelsToBeUpdated;
// }

// Function to Find All the SubHeading With in a Section
// ? done Corrected deleted
export const findSubheadingsInSection = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection
) => {
  const sectionID = section.id;
  const childSubsections: any[] = [];

  try {
    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === sectionID) {
        if (currentSection.subsections) {
          currentSection.subsections.forEach(
            (currentSubsection, subSectionIndex) => {
              if (!isSubsectionDeleted(currentSubsection)) {
                if (currentSubsection && currentSubsection.elements?.length) {
                  // get elements of the current subsection
                  if (
                    currentSubsection.elements[0].content_type === "HEADING"
                  ) {
                    childSubsections.push({
                      type: currentSubsection.elements[0].content_type,
                      SubsectionID: currentSubsection.id,
                      ComponentOrder: currentSubsection.component_order,
                      heading_Level:
                        attachedSubsectionElement(currentSubsection)
                          .heading_level,
                      ElementID:
                        attachedSubsectionElement(currentSubsection).id,
                    });
                  }
                }
              }
            }
          );
        }
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    // Return an empty array or handle the error based on your requirements
    return childSubsections;
  }
  return childSubsections;
};

// Function to Find All the Text Elemnt With out a Parent SubHeading  (Basicly Parent is a Section)
/// ? deleted fixed here

export const findTextElementWithoutParentSubHeading = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection
) => {
  const childSubsections: any[] = [];
  let parentLevelFound = false;
  const sectionID = section.id;
  try {
    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === sectionID) {
        if (currentSection.subsections) {
          currentSection.subsections.some(
            (currentSubsection, subSectionIndex) => {
              if (
                currentSubsection &&
                currentSubsection.pending_deleted_at === null
              ) {
                // get elements of the current subsection
                const elements = currentSubsection.elements;

                // if elements exist
                if (elements && elements.length > 0) {
                  if (
                    elements[0].content_type === "TEXT" &&
                    attachedSubsectionElement(currentSubsection)
                      .heading_level !== 1 &&
                    JSON.parse(
                      attachedSubsectionElement(currentSubsection)
                        ?.content_format
                    )?.isNumberDisplay
                  ) {
                    childSubsections.push({
                      type: elements[0].content_type,
                      SubsectionID: currentSubsection.id,
                      heading_Level:
                        attachedSubsectionElement(currentSubsection)
                          .heading_level,
                      componentOrder: currentSubsection.component_order,
                      ElementID:
                        attachedSubsectionElement(currentSubsection).id,
                    });
                  } else if (elements[0].content_type === "HEADING") {
                    parentLevelFound = true;
                    return true; // Stop iterating further
                  }
                }
              }
              return false; // Continue iterating
            }
          );
        }
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    // Return an empty array or handle the error based on your requirements
    return childSubsections;
  }
  return childSubsections;
};

// Find the Child Text Element of a SubHeading
export const findTextElementBelowSubHeading = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  const childSubsections: any[] = [];
  let parentLevelFound = false;
  const subsectionID = subsection.id;
  const sectionID = section.id;

  try {
    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === sectionID) {
        if (currentSection.subsections) {
          currentSection.subsections.forEach(
            (currentSubsection, subSectionIndex) => {
              if (currentSubsection.id === subsectionID) {
                // get elements of the current subsection
                const elements = currentSubsection.elements;
                // if elements exist
                if (elements && elements.length > 0) {
                  // find the heading level of the current subsection
                  const subsectionLevel = elements[0].heading_level;
                  // if the subsection is not empty
                  if (currentSection.subsections !== undefined) {
                    // now loop below the found element from the section
                    for (
                      let i = subSectionIndex + 1;
                      i < currentSection.subsections.length;
                      i++
                    ) {
                      // select the next subsection
                      const currentChildSubsection =
                        currentSection.subsections[i];
                      // select the elements from the subsection
                      const childElements = currentChildSubsection.elements;
                      // check if elements exist
                      if (childElements && childElements.length > 0) {
                        // select the current element's level
                        const childSubsectionLevel = attachedSubsectionElement(
                          currentChildSubsection
                        ).heading_level;
                        if (
                          childElements[0].content_type === "TEXT" &&
                          attachedSubsectionElement(currentChildSubsection)
                            .heading_level !== 1 &&
                          JSON.parse(
                            attachedSubsectionElement(currentChildSubsection)
                              ?.content_format
                          )?.isNumberDisplay &&
                          currentChildSubsection.pending_deleted_at === null
                        ) {
                          childSubsections.push({
                            ParentSubHeading: subsection,
                            type: childElements[0].content_type,
                            SubsectionID: currentChildSubsection.id,
                            heading_Level: childSubsectionLevel,
                            ElementID: attachedSubsectionElement(
                              currentChildSubsection
                            ).id,
                            subSection: currentChildSubsection,
                          });
                        } else if (
                          childElements[0].content_type === "HEADING"
                        ) {
                          // If a heading is encountered, stop looking for text elements below
                          return childSubsections;
                        }
                      }
                    }
                  }
                }
              }
            }
          );
        }
        parentLevelFound = true;
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    // Return an empty array or handle the error based on your requirements
    return childSubsections;
  }
  return childSubsections;
};

const isSubsectionDeleted = (documentSubSection: DocumentSubsection) => {
  if (documentSubSection.pending_deleted_at != null) {
    return true;
  } else {
    return false;
  }
};

export const findChildSubSectionsToUpdateTextLevels = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  currentElemetType?: string
) => {
  const childSubsections: any = [];
  // let parentLevelFound = false;
  const subsectionID = subsection.id;
  const sectionID = section.id;
  try {
    const currentSection = documentTemplate.sections?.find(
      (_section: DocumentSection) => _section.id == section.id
    );
    if (currentSection) {
      if (currentSection.subsections) {
        currentSection.subsections.forEach(
          (currentSubsection, subSectionIndex) => {
            if (currentSubsection.id === subsectionID) {
              const elements = currentSubsection.elements;

              if (elements && elements[0]) {
                const subsectionLevel =
                  attachedSubsectionElement(currentSubsection).heading_level;

                if (currentSection.subsections != undefined) {
                  for (
                    let i = subSectionIndex + 1;
                    i < currentSection.subsections.length;
                    i++
                  ) {
                    const currentChildSubsection =
                      currentSection.subsections[i];
                    const childElement =
                      currentChildSubsection.elements &&
                      currentChildSubsection.elements[0]
                        ? currentChildSubsection.elements[0]
                        : elements[0];
                    const childSubsectionLevel = childElement.heading_level;
                    if (
                      childElement.content_type == ContentType.Text &&
                      subsectionLevel < childSubsectionLevel &&
                      subsection.pending_deleted_at == null
                    ) {
                      childSubsections.push({
                        type: childElement.content_type,
                        SubsectionID: currentChildSubsection.id,
                        heading_Level: childElement.heading_level,
                        // childSubsectionLevel,
                        ElementID: childElement,
                        subSection: currentChildSubsection,
                        isNumberDisplay: JSON.parse(childElement.content_format)
                          .isNumberDisplay,
                      });
                    } else if (
                      (subsectionLevel >= childSubsectionLevel &&
                        childElement.content_type == ContentType.Text) ||
                      childElement.content_type == ContentType.Heading
                    ) {
                      return childSubsections;
                    }
                  }
                }
              }
            }
          }
        );
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
  return childSubsections;
};

// JSON.parse(
//   attachedSubsectionElement(subSection)?.content_format
// )?.isNumberDisplay
export const findChildSubSectionsToUpdateHeadingLevels = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  currentElemetType?: string
) => {
  const childSubsections: any = [];
  // let parentLevelFound = false;
  const subsectionID = subsection.id;
  const sectionID = section.id;
  try {
    const currentSection = documentTemplate.sections?.find(
      (_section: DocumentSection) => _section.id == section.id
    );
    if (currentSection) {
      if (currentSection.subsections) {
        currentSection.subsections.forEach(
          (currentSubsection, subSectionIndex) => {
            if (currentSubsection.id === subsectionID) {
              const elements = currentSubsection.elements;

              if (elements && elements[0]) {
                const subsectionLevel =
                  attachedSubsectionElement(currentSubsection).heading_level;

                if (currentSection.subsections != undefined) {
                  for (
                    let i = subSectionIndex + 1;
                    i < currentSection.subsections.length;
                    i++
                  ) {
                    const currentChildSubsection =
                      currentSection.subsections[i];
                    const childElement =
                      currentChildSubsection.elements &&
                      currentChildSubsection.elements[0]
                        ? currentChildSubsection.elements[0]
                        : elements[0];
                    const childSubsectionLevel = childElement.heading_level;
                    if (
                      (childElement.content_type == ContentType.Text ||
                        childElement.content_type == ContentType.Heading) &&
                      subsectionLevel < childSubsectionLevel &&
                      subsection.pending_deleted_at == null
                    ) {
                      childSubsections.push({
                        type: childElement.content_type,
                        SubsectionID: currentChildSubsection.id,
                        heading_Level: childElement.heading_level,
                        // childSubsectionLevel,
                        ElementID: childElement,
                        subSection: currentChildSubsection,
                        isNumberDisplay: JSON.parse(childElement.content_format)
                          .isNumberDisplay,
                      });
                    } else if (
                      subsectionLevel >= childSubsectionLevel &&
                      (childElement.content_type == ContentType.Heading ||
                        childElement.content_type == ContentType.Text)
                    ) {
                      return childSubsections;
                    }
                  }
                }
              }
            }
          }
        );
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
  return childSubsections;
};
