import {
  AccountCircle,
  AccountCircleOutlined,
  CancelRounded,
  DeleteOutline,
  CheckCircle,
  ReplayCircleFilledOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import { blue, green, grey, red } from "@mui/material/colors";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditIcon from "@mui/icons-material/Edit";
import { isTemplateExpression } from "typescript";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/app/rootReducer";
import HighlightedComment from "./HighlightComment";
import { isUserFromShareLink } from "../editor/document/TableElement/EditContent/helper";
import { UTCtoLocalTime } from "../editor/document/utils";
import { setCommentIds } from "./commentingDetailsSlice";

type Props = {
  item: any;
  fromEditor?: boolean;
  onReply?: any;
  showActions?: boolean;
  fromReply?: boolean;
  showUserIcons?: any;
  onEdit?: any;
  isEdit?: boolean;
  editValue?: any;
  onCommentChange?: any;
  editedComment?: any;
  onSubmitEditedComment?: any;
  isReply?: boolean;
  onDeleteComment?: any;
  onResolveComment?: any;
  onUnResolveComment?: any;
};

const CommentsCard = (props: Props) => {
  const [value, setvalue] = useState(props.editedComment);
  const [activeSubsectionId, setActiveSubsectionId] = useState<
    string | number | null
  >(null);

  const dispatch = useDispatch();

  const selectedCommentingItem = useSelector(
    (state: RootState) => state.apiCommenting.selectedItem
  );

  const fromReviewComments = useSelector(
    (state: RootState) => state.apiCommenting.fromReviewComments
  );

  const fromReviewCommentsMain = useSelector(
    (state: RootState) => state.apiCommenting.fromReviewCommentsMain
  );

  const { commentIds } = useSelector((state: RootState) => state.apiCommenting);

  const removeCommentId = (id: number, comment_id: number | any) => {
    let removed = false;
    const newData = commentIds.filter((comment) => {
      if (comment.supercedes_id === comment_id) {
        return false;
      }
      if (
        !removed &&
        comment.id === id &&
        (comment.supercedes_id === null || comment.supercedes_id === undefined)
      ) {
        removed = true;
        return false;
      }
      return true;
    });

    dispatch(setCommentIds(newData));
  };

  // console.log(fromReviewComments, fromReviewCommentsMain);
  // console.log("commentids", commentIds);

  useEffect(() => {
    if (props.editedComment) setvalue(props.editedComment);
  }, [props.editedComment]);

  const EditIconCard = () => (
    <Tooltip
      title={props.editValue ? "Cancel Edit" : "Edit"}
      placement="top"
      onClick={props.onEdit}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {props.editValue && props.editValue.id === props.item.id ? (
        <CancelRounded
          sx={{
            color: grey[600],
            cursor: "pointer",
            fontSize: "14px",
          }}
        />
      ) : (
        <EditIcon
          sx={{
            color: grey[600],
            cursor: "pointer",
            fontSize: "14px",
          }}
        />
      )}
    </Tooltip>
  );
  const scrollToSubSection = (
    subSectionId: string | number,
    sectionId: string | number
  ) => {
    const element = document.querySelector(
      subSectionId ? `#subsection-${subSectionId}` : `#section-${sectionId}`
    );

    if (element) {
      setActiveSubsectionId(subSectionId || sectionId);

      const targetElement = element as HTMLElement;
      // Scroll into view
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
      targetElement.style.outline = "1px solid #4c3fff";

      setTimeout(() => {
        targetElement.style.outline = "none";
        setActiveSubsectionId(null);
      }, 3000);
    }
  };

  useEffect(() => {
    return () => {
      if (activeSubsectionId) {
        const activeElement = document.getElementById(
          `subsection-${activeSubsectionId}`
        );
        const activeSection = document.getElementById(
          `section-${activeSubsectionId}`
        );

        if (activeElement) {
          activeElement.style.outline = "none";
        }
        if (activeSection) {
          activeSection.style.outline = "none";
        }
      }
    };
  }, [activeSubsectionId]);

  return (
    <Box
      sx={{
        backgroundColor: props.showActions ? grey[100] : grey[100],
        borderRadius: props.showActions ? "8px" : "8px",
        position: "relative",
        padding: "6px",
        marginX: "6px",
        marginY: props.showActions ? "3px" : "3px",
        zIndex: 999,
        cursor: "pointer",
        transition: "transform 0.2s ease, background-color 0.2s ease",
        "&:hover": {
          backgroundColor: grey[200], // Hover background change
          transform: "scale(1.02)", // Slight scale effect on hover
        },
      }}
      width={props.fromEditor ? "100%" : "94%"}
      onClick={() =>
        scrollToSubSection(
          props.item.document_sub_section_id,
          props.item.document_section_id
        )
      }
    >
      <Box
        alignItems={"center"}
        position={"sticky"}
        justifyContent={"space-between"}
        display={"flex"}
        sx={{
          position: "relative",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <AccountCircle style={{ color: "gray" }} />
          <Typography fontSize={"12px"} fontWeight={"bold"}>
            {props.item?.name}
          </Typography>
        </Box>
        {props.showActions && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            {!props.isReply && (
              <Tooltip title="Reply" placement="top" onClick={props.onReply}>
                <ReplyIcon
                  sx={{
                    color: grey[600],
                    cursor: "pointer",
                    fontSize: "16px",
                    "&:hover": { color: blue[600] },
                    transition: "color 0.3s",
                  }}
                />
              </Tooltip>
            )}
            {props.showUserIcons(
              props.item.commenter_id || props.item.user_id
            ) ? (
              <Tooltip
                title="Delete"
                placement="top"
                onClick={() => {
                  removeCommentId(
                    props.item.document_sub_section_id
                      ? props.item.document_sub_section_id
                      : props.item.document_section_id,
                    props.item.id
                  );
                  props.onDeleteComment();
                }}
              >
                <DeleteOutline
                  sx={{
                    color: grey[600],
                    cursor: "pointer",
                    fontSize: "16px",
                    "&:hover": { color: red[600] },
                    transition: "color 0.3s",
                  }}
                />
              </Tooltip>
            ) : fromReviewComments || fromReviewCommentsMain ? (
              <Tooltip
                title="Delete"
                placement="top"
                onClick={() => {
                  removeCommentId(
                    props.item.document_sub_section_id
                      ? props.item.document_sub_section_id
                      : props.item.document_section_id,
                    props.item.id
                  );
                  props.onDeleteComment();
                }}
              >
                <DeleteOutline
                  sx={{
                    color: grey[600],
                    cursor: "pointer",
                    fontSize: "16px",
                    "&:hover": { color: red[600] },
                    transition: "color 0.3s",
                  }}
                />
              </Tooltip>
            ) : null}
            {props.showUserIcons(
              props.item.commenter_id || props.item.user_id
            ) && <EditIconCard />}
            {props.showUserIcons && props?.item?.is_resolved ? (
              <Tooltip
                title="Unresolve comment"
                placement="top"
                onClick={
                  isUserFromShareLink() ? undefined : props.onUnResolveComment
                }
              >
                <CheckCircle
                  sx={{
                    color: green[600],
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="Resolve comment"
                placement="top"
                onClick={
                  isUserFromShareLink() ? undefined : props.onResolveComment
                }
              >
                <CheckCircle
                  sx={{
                    color: grey[600],
                    cursor: "pointer",
                    fontSize: "16px",
                    "&:hover": { color: green[600] },
                    transition: "color 0.3s",
                  }}
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
      {!props.fromReply ? (
        props.isEdit && props.item.id === props.editValue.id ? (
          <>
            <TextField
              id="outlined-comment"
              value={value}
              onChange={(e) => setvalue(e.target.value)}
              fullWidth
              multiline
              minRows={3}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: grey[700],
                },
              }}
              color="primary"
              InputProps={{
                style: {
                  fontSize: "12px",
                  padding: "12px",
                },
              }}
              focused={true}
              sx={{
                width: "100%",
                maxWidth: "600px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&:hover fieldset": {
                    borderColor: blue[300],
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: blue[500],
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IconButton
                disabled={value === props.item.comment || !value}
                sx={{
                  color: blue[500],
                  backgroundColor: blue[50],
                  transition: "background-color 0.2s ease, color 0.2s ease",
                  "&:hover": {
                    backgroundColor: blue[100],
                  },
                }}
                onClick={() => {
                  props.onSubmitEditedComment(value, props.item.comment);
                }}
              >
                <SendIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </>
        ) : (
          <HighlightedComment item={props.item} fromEditor={props.fromEditor} />
        )
      ) : (
        <HighlightedComment item={props.item} fromEditor={props.fromEditor} />
      )}
      {!props.fromReply && (
        <Typography
          fontSize={"10px"}
          textAlign={"right"}
          color={"#4c3fff"}
          width={"100%"}
        >
          {props.item.updated_at && "(edited)"}{" "}
          {UTCtoLocalTime(props.item.created_at)}
        </Typography>
      )}
      {/* <Divider /> */}
    </Box>
  );
};

export default CommentsCard;
