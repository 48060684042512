interface CrossRefs {
  references: any[];
  referencesData: any[];
  isRefMissing: boolean;
  hiddenNumberId: any[];
  data: any;
  isLibContentOpen: boolean;
  selectedLibContent: any;
  sanitizedData: any[];
  showAddSection:boolean
}

const initialReferencesState: CrossRefs = {
  references: [],
  referencesData: [],
  isRefMissing: false,
  hiddenNumberId: [],
  data: null,
  isLibContentOpen: false,
  selectedLibContent: null,
  sanitizedData: [],
  showAddSection:false
};

// Action Types
const SET_REFERENCES_DATA = "SET_REFERENCES_DATA";
const SET_REFERENCES_DATA_COMPLETE = "SET_REFERENCES_DATA_COMPLETE";

const SET_IS_REF_MISSING = "SET_IS_REF_MISSING";
const SET_HIDDEN_NUMBERS = "SET_HIDDEN_NUMBERS";
const SET_SANITIZED_CR_DATA = "SET_SANITIZED_CR_DATA";
const IS_LIB_CONTENT_OPEN = "IS_LIB_CONTENT_OPEN";
const SET_SELECTED_LIB_CONTENT = "SET_SELECTED_LIB_CONTENT";
const SET_SANITIZED_DATA = "SET_SANITIZED_DATA";
const SHOW_ADD_SECTION="SHOW_ADD_SECTION"

// Action Creators
export const setShowAddSection = (data: any) => ({
  type: SHOW_ADD_SECTION,
  payload: data,
});


export const setSanitizedData = (data: any) => ({
  type: SET_SANITIZED_DATA,
  payload: data,
});

export const setCrossReferencesData = (data: any) => ({
  type: SET_SANITIZED_CR_DATA,
  payload: data,
});

export const setCrossReferences = (data: any) => ({
  type: SET_REFERENCES_DATA,
  payload: data,
});

export const setCompleteCrossReferencesData = (data: any) => ({
  type: SET_REFERENCES_DATA_COMPLETE,
  payload: data,
});

export const setIsRefMissing = (data: any) => ({
  type: SET_IS_REF_MISSING,
  payload: data,
});

export const setHiddenNumbers = (data: any) => ({
  type: SET_HIDDEN_NUMBERS,
  payload: data,
});

export const setIsLibraryContentAvailable = (data: any) => ({
  type: IS_LIB_CONTENT_OPEN,
  payload: data,
});

export const setSelectedLibraryContent = (data: any) => ({
  type: SET_SELECTED_LIB_CONTENT,
  payload: data,
});

// Reducer
const crossReferenceReducer = (
  state = initialReferencesState,
  action: any
): CrossRefs => {
  switch (action.type) {
    case SHOW_ADD_SECTION:
      return { ...state, showAddSection: action.payload };
    case SET_SANITIZED_CR_DATA:
      return { ...state, data: action.payload };
    case SET_SANITIZED_DATA:
      return { ...state, sanitizedData: action.payload };
    case SET_REFERENCES_DATA:
      return { ...state, references: action.payload };
    case SET_REFERENCES_DATA_COMPLETE:
      return { ...state, referencesData: action.payload };
    case SET_IS_REF_MISSING:
      return { ...state, isRefMissing: action.payload };
    case IS_LIB_CONTENT_OPEN:
      return { ...state, isLibContentOpen: action.payload };
    case SET_SELECTED_LIB_CONTENT:
      return { ...state, selectedLibContent: action.payload };
    default:
      return state;
  }
};

export default crossReferenceReducer;
