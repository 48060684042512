// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import { getLocalUserToken } from "../services/userAPI";
// export const host = "https://tapi.nomiadocs.com/";
export const host = "https://api.nomiadocs.com/";
// export const host = "http://192.168.100.2:8000";
// export const host = "https://30d5-103-101-232-206.ngrok-free.app"
import { decryptAES } from "./encryption";
export async function client(
  endpoint,
  { body, isBinary, ...customConfig } = {},
  type,
  returnWholeError
) {
  const headers = {
    "Content-Type": "application/json",
    //  "ngrok-skip-browser-warning": "69420"
  };
  // Get the auth token if it exists
  const token = getLocalUserToken();
  if (token) headers["Authorization"] = token;
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  let data;
  try {
    const response = await window.fetch(host + endpoint, config);
    if (response.ok) {
      data = await (isBinary ? response.blob() : response.json());
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }
    //Error case
    const text = await response.text();

    // eslint-disable-next-line no-undef
    console.error(
      `Called: ${host + endpoint}\r\nStatus: ${
        response.statusText
      }\r\nResponse body: ${text}`
    );

    if (returnWholeError) {
      throw new Error(response.status);
    }

    throw new Error(response.statusText);
    // throw new Error(text);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}, type) {
  return client(endpoint, { ...customConfig, method: "GET" }, type);
};

client.getWithData =async function (endpoint, body = {}, customConfig = {}) {
  const config = {
    ...customConfig,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...customConfig.headers,
    },
    body: JSON.stringify(body), 
  };

  return client(endpoint, config).then((response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw err;
      });
    }
    return response.json();
  });
};



client.post = function (endpoint, body, customConfig = {}, returnWholeError) {
  return client(endpoint, { ...customConfig, body }, {}, returnWholeError);
};

// TODO: Confirm these work
client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: "PUT" });
};

client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: "DELETE" });
};
