import { client } from "./client";

export function apiGetDocumentReferences(
  organization_id: number,
  workspace_id: number,
  document_template_id: number
) {
  return client.get(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/cross_referencing`
  );
}

export function apiGetCrossReferedElementIds(
  organization_id: number | undefined,
  workspace_id: number | undefined,
  document_template_id: number | undefined,
  payload: any
) {
  return client.post(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/references`,
    payload
  );
}
