interface CommentingDetailsState {
  commenter: string | null;
  comments: any;
  allComments: any;
  selectedItem: any;
  reviewComments: any;
  fromReviewComments: boolean;
  fromReviewCommentsMain: boolean;
  isPopOverOpen: boolean;
  isCommentsActivated: boolean;
  externalUser: any;
  commentIds: any[];
  allDocComments: any;
}

const initialCommentingState: CommentingDetailsState = {
  commenter: null,
  comments: null,
  allComments: null,
  selectedItem: null,
  reviewComments: [],
  fromReviewComments: false,
  fromReviewCommentsMain: false,
  isPopOverOpen: false,
  isCommentsActivated: false,
  externalUser: null,
  commentIds: [],
  allDocComments: null,
};

// Action Types
const SET_COMMENTER_DATA = "SET_COMMENTER_DATA";
const SET_CURRENT_COMMENTS = "SET_CURRENT_COMMENTS";
const SET_ALL_COMMENTS = "SET_ALL_COMMENTS";
const SET_ALL_DOC_COMMENTS = "SET_ALL_DOC_COMMENTS";
const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
const SET_REVIEW_COMMENTS = "SET_REVIEW_COMMENTS";
const SET_FROM_REVIEW_COMMTENTS = "SET_FROM_REVIEW_COMMTENTS";
const SET_FROM_REVIEW_COMMTENTS_MAIN = "SET_FROM_REVIEW_COMMTENTS_MAIN";
const SET_POPOVER_OPEN = "SET_POPOVER_OPEN";
const SET_IS_COMMENTS_ACTIVATED = "SET_IS_COMMENTS_ACTIVATED";
const SET_EXTERNAL_USER = "SET_EXTERNAL_USER";
const SET_COMMENT_IDS = "SET_COMMENT_IDS";
const ADD_COMMENT_ID = "ADD_COMMENT_ID";
const REMOVE_COMMENT_ID = "REMOVE_COMMENT_ID";

// Action Creators
export const setExternalUser = (data: any) => ({
  type: SET_EXTERNAL_USER,
  payload: data,
});

export const setCommenter = (data: any) => ({
  type: SET_COMMENTER_DATA,
  payload: data,
});

export const setCurrentComments = (data: any) => ({
  type: SET_CURRENT_COMMENTS,
  payload: data,
});

export const setAllComments = (data: any) => ({
  type: SET_ALL_COMMENTS,
  payload: data,
});

export const setAllDocComments = (data: any) => ({
  type: SET_ALL_DOC_COMMENTS,
  payload: data,
});

export const setSelectedCommentingItem = (data: any) => ({
  type: SET_SELECTED_ITEM,
  payload: data,
});

export const setReviewComments = (data: any) => ({
  type: SET_REVIEW_COMMENTS,
  payload: data,
});

export const setFromReviewComments = (data: any) => ({
  type: SET_FROM_REVIEW_COMMTENTS,
  payload: data,
});

export const setFromReviewCommentsMain = (data: any) => ({
  type: SET_FROM_REVIEW_COMMTENTS_MAIN,
  payload: data,
});

export const setPopoverOpen = (data: any) => ({
  type: SET_POPOVER_OPEN,
  payload: data,
});

export const setIsCommentsActivated = (data: any) => ({
  type: SET_IS_COMMENTS_ACTIVATED,
  payload: data,
});

export const setCommentIds = (data: any) => ({
  type: SET_COMMENT_IDS,
  payload: data,
});

// Reducer
const apiCommentingReducer = (
  state = initialCommentingState,
  action: any
): CommentingDetailsState => {
  switch (action.type) {
    case SET_COMMENTER_DATA:
      return { ...state, commenter: action.payload };
    case SET_EXTERNAL_USER:
      return { ...state, externalUser: action.payload };
    case SET_REVIEW_COMMENTS:
      return { ...state, reviewComments: action.payload };
    case SET_FROM_REVIEW_COMMTENTS:
      return { ...state, fromReviewComments: action.payload };
    case SET_FROM_REVIEW_COMMTENTS_MAIN:
      return { ...state, fromReviewCommentsMain: action.payload };
    case SET_POPOVER_OPEN:
      return { ...state, isPopOverOpen: action.payload };
    case SET_IS_COMMENTS_ACTIVATED:
      return { ...state, isCommentsActivated: action.payload };
    case SET_CURRENT_COMMENTS:
      return { ...state, comments: action.payload };
    case SET_ALL_COMMENTS:
      return { ...state, allComments: action.payload };
    case SET_ALL_DOC_COMMENTS:
      return { ...state, allDocComments: action.payload };
    case SET_COMMENT_IDS:
      return { ...state, commentIds: action.payload };
    case SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };
    default:
      return state;
  }
};

export default apiCommentingReducer;
