import { MessageOutlined } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { blue, grey, red } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/app/store";
import { setCommentIds } from "./commentingDetailsSlice";

type Props = {
  value: string;
  onComment: any;
  onChangeValue: any;
  fromEditor?: boolean;
  isReply?: boolean;
  valid_sid?: number;
  valid_ssid?: number;
  supercedes_id?: number | null | undefined;
};

const AddCommentCard = (props: Props) => {
  const dispatch = useDispatch();
  const { name, email } = useSelector(
    (state: RootState) => state.apiCommenting.commenter
  ) as any;

  const { commentIds } = useSelector((state: RootState) => state.apiCommenting);

  const addCommentId = (id: number | any, supercedes_id: number | any) => {
    const payload = {
      id,
      supercedes_id,
    };
    const newData = [...commentIds, payload];
    dispatch(setCommentIds(newData));
  };

  return (
    <Box
      sx={{
        bgcolor: props.fromEditor ? "#FFFFFF" : grey[100],
        padding: props.fromEditor ? "10px" : "20px",
        borderRadius: "12px",
        boxShadow: props.fromEditor
          ? "none"
          : "0px 4px 10px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease",
      }}
    >
      {/* Comment Input */}
      <Box
        sx={{
          bgcolor: props.fromEditor ? "#FFFFFF" : grey[100],
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <TextField
          id="outlined-comment"
          label={props.isReply ? "Add reply" : "Add comment"}
          value={props.value}
          onChange={props.onChangeValue}
          fullWidth
          multiline
          minRows={3}
          InputLabelProps={{
            style: {
              fontSize: 16,
              color: grey[700],
            },
          }}
          color="primary"
          InputProps={{
            style: {
              fontSize: 14,
              padding: "12px",
            },
          }}
          sx={{
            width: "100%",
            maxWidth: "600px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "&:hover fieldset": {
                borderColor: blue[300],
              },
              "&.Mui-focused fieldset": {
                borderColor: blue[500],
              },
            },
          }}
          placeholder={
            props.isReply
              ? "Type your reply here..."
              : "Type your comment here..."
          }
          onKeyPress={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault(); // Prevent accidental form submission
              props.onComment();
            }
          }}
        />
      </Box>

      {/* Footer with Commenter Info and Send Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <Typography paddingX={"5px"} fontSize={"12px"} color={grey[600]}>
          Commenting as <b>{name}</b>
        </Typography>

        <IconButton
          disabled={!props.value}
          sx={{
            color: !props.value ? grey[400] : blue[500],
            backgroundColor: props.value ? blue[50] : grey[200],
            transition: "background-color 0.2s ease, color 0.2s ease",
            "&:hover": {
              backgroundColor: props.value ? blue[100] : grey[300],
            },
          }}
          onClick={() => {
            addCommentId(
              props.valid_ssid ? props.valid_ssid : props.valid_sid,
              props.supercedes_id
            );
            props.onComment();
          }}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AddCommentCard;
