import * as React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { HelpOutline } from "@mui/icons-material"; // Import icons
import spcerrr from "./icons/Create Heading (Spacer).png";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";

import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import { DragItem } from "./DragItem";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import TableChartIcon from "@mui/icons-material/TableChart";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleRounded";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelSharp";
import ImageIcon from "@mui/icons-material/Image";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TocIcon from "@mui/icons-material/Toc";
import ReplyIcon from "@mui/icons-material/Reply";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import {
  ContentFormat,
  ContentType,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";
import ReferencesTable from "./Variables/ReferencesTable";
import VariablesTable from "./Variables/VariablesTable";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import {
  getImpact,
  selectApprovalMode,
  setImpactSection,
} from "../../editorSlice";
import { selectCurrentWorkspaceRole } from "../../../auth/authSlice";
import { Button, Typography } from "@mui/material";
import { DocumentSection, Workspace } from "../../../../types/DocumentTemplate";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HMobiledataSharpIcon from "@mui/icons-material/HMobiledataSharp";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { fontWeight } from "@mui/system";
import { ApprovalsItems } from "./ApprovalsItems";
import { TABLE_THEMES } from "../../../../globals";
import { isDocumentEmpty } from "../../document/HelperEditorFunctions";
import { isUserFromShareLink } from "../../document/TableElement/EditContent/helper";
// import { HelpOutline } from "@mui/icons-material";

const drawerWidth = "19vw";
// const drawerWidth = 200;
const fontWidth = "27px";
// REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
export function RightDrawer(props: { documentTemplate: DocumentTemplate }) {
  if (isUserFromShareLink()) {
    return (
      <Box
        sx={{
          width: "500px",
          p: 2,
          marginTop: 6,
          borderRadius: 2,
          boxShadow: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            paddingY: 2,
          }}
        >
          <HelpOutline style={{ color: "gray", fontSize: "24px" }} />
          <Typography
            sx={{ marginLeft: 1, fontWeight: "bold", fontSize: "14px" }}
          >
            Instructions for commenting
          </Typography>
        </Box>

        <Box sx={{ marginTop: 0 }}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <AddCommentOutlinedIcon sx={{ marginRight: 1, fontSize: "20px" }} />
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 200,
              }}
            >
              <strong>Adding a new comment:</strong>
            </Typography>
          </div>
          <ul
            style={{
              marginTop: -5,
              marginLeft: 50,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Option 1: Click on the comment icon next to the element you want
                to comment on, add your comment, and click on the send icon.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Option 2: Select an element, add your comment in the comment box
                located in the bottom left corner of your screen, and click on
                the send icon.
              </Typography>
            </li>
          </ul>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <ChangeCircleIcon sx={{ marginRight: 1, fontSize: "20px" }} />
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <strong>Propose a change to an element:</strong>
            </Typography>
          </div>
          <ul
            style={{
              marginTop: -5,
              marginLeft: 50,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Click on the comment icon next to the element you want to mark
                up.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Click on “Copy content and mark up” in the comment modal.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Edit the copied content and click on the send icon.
              </Typography>
            </li>
          </ul>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <ReplyIcon sx={{ marginRight: 1, fontSize: "20px" }} />
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <strong>Reply to comments:</strong>
            </Typography>
          </div>
          <ul
            style={{
              marginTop: -5,
              marginLeft: 50,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Click on the reply icon on the required comment in the comment
                pane located on the left of your screen.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Add your reply and click on the send icon.
              </Typography>
            </li>
          </ul>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <ModeEditIcon sx={{ marginRight: 1, fontSize: "20px" }} />
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <strong>Edit comments:</strong>
            </Typography>
          </div>
          <ul
            style={{
              marginTop: -5,
              marginLeft: 50,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Click on the edit icon on the required comment in the comment
                pane located on the left of your screen.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Edit the comment and click on the send icon.
              </Typography>
            </li>
          </ul>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <DeleteForeverIcon sx={{ marginRight: 1, fontSize: "18px" }} />
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <strong>Delete comments:</strong>
            </Typography>
          </div>
          <ul
            style={{
              marginTop: -5,
              marginLeft: 50,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Click on the delete icon on the required comment in the comment
                pane located on the left of your screen.
              </Typography>
            </li>
          </ul>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavigateNextIcon sx={{ marginRight: 1 }} />
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <strong>Navigating and accessing comments:</strong>
            </Typography>
          </div>
          <ul
            style={{
              marginTop: -5,
              marginLeft: 50,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Elements that have comments attached have a pink icon next to
                them.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Clicking on a comment in the left-hand comment pane navigates
                you to the relevant element in the document.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Clicking on an element in the document filters the list of
                comments in the left-hand comment pane.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                Clicking on "Show all comments" removes all comment filters and
                displays all document comments in the left-hand comment pane.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0.5,
                }}
              >
                If the document owner has closed the document for commenting,
                you will no longer be able to access the document, and the link
                provided will no longer work.
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    );
  }

  const isSection = isDocumentEmpty(props.documentTemplate);
  // console.log("🚀 ~ file: RightDrawer.tsx:344 ~ RightDrawer ~ isSection:", isSection)
  const isSectionExist = !isSection?.isDocumentEmpty;

  // const isSectionExist = true;
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const impact = useAppSelector(getImpact);

  const contentList = [
    {
      icon: (
        <DocumentScannerIcon
          style={{ fontSize: 25, outline: "none", color: "black" }}
        />
      ),
      text: "Title page",
      dragType: "right-drawer-item-title-page",
      element: {
        id: -1,
        content: "----- !cover page! -----",
        content_format: ContentFormat.Text,
        content_type: ContentType.TitlePage,
        heading_level: 6,
      },
      enabled: true,
    },

    {
      icon: (
        <TocIcon style={{ fontSize: 30, outline: "none", color: "black" }} />
      ),
      text: "Table of contents",
      dragType: "right-drawer-item-Toc",
      element: {
        id: -1,
        content: "----- !table-of-contents! -----",
        content_format:
          '{"title":"Table-of-content","levelDepth":"3","font":"Arial","fontSize":"12" , "color": "000000", "lineSpacing": "1.5", "indent" :"true" , "border": "F" , "isPageBreakBelow" :false , " isPageBreakAbove": false ,isFollowDtm:true ,"isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}',
        content_type: ContentType.TableOfContents,
        heading_level: 6,
      },
      enabled: true,
    },

    {
      icon: (
        <ListAltIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "List of tables/figures",
      dragType: "right-drawer-item-LTF",
      element: {
        id: -1,
        content: "----- !list-of-figures! -----",
        // content_format: ContentFormat.Text,
        content_format:
          '{"title":"List-of-Figure","font":"arial","fontSize":"12" , "color": "000000", "lineSpacing": "1.5" ,  "type":"List of Tables" ,"isPageBreakBelow" :false , " isPageBreakAbove": false , isFollowDtm:true , "isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}',
        content_type: ContentType.ListOfFigures,
        heading_level: 6,
      },
      enabled: true,
    },
    {
      icon: (
        <MenuBookIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Library content",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: "",
        content_format: ContentFormat.Markdown,
        content_type: ContentType.LibraryContentSubSection,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },

    {
      icon: (
        <HMobiledataSharpIcon
          style={{ fontSize: 33, outline: "none", color: "black" }}
        />
      ),
      text: "Heading",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: "## Subheading",
        content_format: JSON.stringify({
          format: ContentFormat.Markdown,
          color: "000000",
          justify: "raggedright",
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStandard: true,
          indent: 0,
          isColorApplied: false,
          isJustificationApplied: false,
          isNumberDisplay: true,
          isIndentApplied: false,
        }),
        content_type: ContentType.Heading,
        heading_level: 2,
        formatting: "{}",
      },
      // enabled: true,
      enabled: isSectionExist,
    },
    {
      icon: (
        <TextFieldsIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Text",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        // content: 'Add Content Here',
        content: "",
        content_format: JSON.stringify({
          format: ContentFormat.Markdown,
          color: "000000",
          justify: "raggedright",
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStandard: true,
          indent: 0,
          isColorApplied: false,
          isJustificationApplied: false,
          isNumberDisplay: true,
          isIndentApplied: false,
        }),
        content_type: ContentType.Text,
        heading_level: 3,
      },
      // enabled: true,
      enabled: isSectionExist,
    },

    {
      icon: (
        <ImageIcon style={{ fontSize: 30, outline: "none", color: "black" }} />
      ),
      text: "Image",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: "",
        content_format:
          '{"size":"Original","custom":100,"justify":"center","caption":"Image","DisplyCaption":true,"captionOrder":"below","captionAlignment":"center","font":"arial","fontSize":"footnotesize","color":"black","style":"bold","isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}',
        content_type: ContentType.Image,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },
    {
      icon: (
        <TableChartIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Table",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: "!TC!TC!TR!TC!TC!TR!TC!TC!TR!TC!TC",
        content_format: JSON.stringify({
          format: ContentFormat.Markdown,
          isDynamicTable: false,
          dynamicVariable: "",
          columns: 3,
          rows: 4,
          caption: "New Table",
          displayCaption: false,
          captionAlignment: "left",
          captionOrder: "above",
          font: "arial",
          fontSize: "large",
          color: "000000",
          justify: "center",
          justifyContent: "left",
          isNumberFormat: false,
          isBold: true,
          isItalic: false,
          isUnderline: false,
          isStandard: false,
          tableFont: "arial",
          tableBorderStyle: "solid",
          tableBorderColor: "000000",
          tableBorderThickness: "0.352",
          captionIndent: 0,
          // table properties
          tableSize: "auto",
          columnSize: "15", // cm
          rowWidth: "0", // cm
          tableWidth: "0",
          colType: "automatic",
          rowType: "automatic",
          leftMargin: 0,
          rightMargin: 0,
          themeDetails: TABLE_THEMES.default,
        }),

        content_type: ContentType.Table,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },

    {
      icon: (
        <SpaceBarIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Spacer",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: '{"type":"Spacer"}',
        content_format: '{"height":"20"}',
        content_type: ContentType.Spacer,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },

    {
      icon: (
        <InsertPageBreakIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Page break",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: "----- !page-break! -----",
        content_format: ContentFormat.Text,
        content_type: ContentType.PageBreak,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },

    // {
    //   icon: (
    //     <TableRowsRoundedIcon
    //       style={{ fontSize: 30, outline: "none", color: "black" }}
    //     />
    //   ),
    //   text: "Dynamic Table",
    //   dragType: "right-drawer-item",
    //   element: {
    //     id: -1,
    //     content: "!TC!TR!TC",
    //     content_format: JSON.stringify({
    //       format: ContentFormat.Markdown,
    //       columns: 3,
    //       rows: 1,
    //       caption: "New Table",
    //       displayCaption: true,
    //       captionAlignment: "center",
    //       captionOrder: "below",
    //       font: "arial",
    //       fontSize: "large",
    //       color: "000000",
    //       justify: "center",
    //       justifyContent: "left",
    //       isNumberFormat: false,
    //       isBold: false,
    //       isItalic: false,
    //       isUnderline: false,
    //       isStandard: true,
    //       tableFont: "arial",
    //       tableBorderStyle: "solid",
    //       tableBorderColor: "000000",
    //       tableBorderThickness: "0.352",
    //       captionIndent:0,
    //    // table properties
    //       tableSize: "auto",
    //       columnSize: "15", // cm
    //       rowWidth: "0", // cm
    //       tableWidth: "0",
    //       colType:"automatic",
    //       rowType:"automatic",
    //       leftMargin:0,
    //       rightMargin:0,
    //       themeDetails:TABLE_THEMES.default
    //     }),

    //     content_type: ContentType.dynamicTable,
    //     heading_level: 6,
    //   },
    //   // enabled: true,
    //   enabled: isSectionExist
    // },

    {
      icon: (
        <ViewColumnIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Columns Start",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: '{"type":"Columns"}',
        content_format: '{"columns":2 , "fillspacing":false}',
        content_type: ContentType.columnStart,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },

    {
      icon: (
        <ViewColumnIcon
          style={{ fontSize: 30, outline: "none", color: "black" }}
        />
      ),
      text: "Columns End",
      dragType: "right-drawer-item",
      element: {
        id: -1,
        content: '{"type":"Columns"}',
        content_format: '{"columns":2 , "fillspacing":false}',
        content_type: ContentType.columnEnd,
        heading_level: 6,
      },
      // enabled: true,
      enabled: isSectionExist,
    },
  ];

  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const iconSize = "20px"; // Set the desired fixed size for the icons
  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      {(currentWorkspaceRole == "CREATOR" ||
        currentWorkspaceRole == "PUBLISHER_CREATOR") && (
        <List>
          {contentList.map((item, index) => (
            <DragItem
              id={index}
              key={index}
              enabled={item.enabled}
              data={item.element}
              element={item.element}
              type={item.dragType}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </List>
      )}
      <Divider />
    </Box>
  );

  const dispatch = useDispatch();
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          mx: 0,
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      anchor="right"
    >
      <Toolbar />
      {!props.documentTemplate.import_mode &&
        !approvalsEnabled &&
        (currentWorkspaceRole == "CREATOR" ||
          currentWorkspaceRole == "PUBLISHER_CREATOR") && (
          <Box>
            {drawer}
            <VariablesTable
              variables={props.documentTemplate.variables}
            ></VariablesTable>
            {/* Remove refrences from sprint  */}
            {/* <ReferencesTable references={props.documentTemplate.references}></ReferencesTable> */}
          </Box>
        )}

      {!props.documentTemplate.import_mode && approvalsEnabled && (
        <Box>
          <List
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    paddingTop: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "11vw",
                  }}
                >
                  {/* Impact */}
                </Typography>
              </ListSubheader>
            }
          ></List>
          <ApprovalsItems></ApprovalsItems>
        </Box>
      )}
    </Drawer>
  );
}
