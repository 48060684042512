import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { selectEditingDocumentTemplate } from "./editorSlice";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import { host } from "../../services/client";
import { selectAuth, selectUser } from "../auth/authSlice";

export default function EditorHeaderFooter(props: {
  title: string;
  type: string;
  font: any;
  fontSize: any;
  fontStyle: any;
  fontColor: any;
  freeText: any;
  selectionChoice: any;
  pageNumberStyle: any;
  imageID: any;
  pageStyle: any;
  position: any;
}) {
  const documentTemplate = useAppSelector(
    selectEditingDocumentTemplate
  ) as DocumentTemplate;
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);

  const default_organization_id =
    auth.user?.default_organization_id || user?.default_orgainization_id;

  const formatReturn = (format: any) => {
    if (format === "None") {
      return "";
    } else if (format === "arabic") {
      return "1";
    } else if (format === "Alph") {
      return "A";
    } else if (format === "alph") {
      return "a";
    } else if (format === "roman") {
      return "i";
    } else if (format === "Roman") {
      return "I";
    } else return "";
  };

  return (
    <Box padding={1} paddingTop={0} paddingBottom={0}>
      {props.selectionChoice === "freeText" ? (
        <Box>
          <Typography
            // variant="h5"
            fontSize={props.fontSize + "pt"}
            fontWeight={props.fontStyle.includes("bold") ? "bold" : "normal"}
            color={"#" + props.fontColor}
            fontFamily={props.font}
            sx={{
              fontStyle: props.fontStyle.includes("italic")
                ? "italic"
                : "normal",
              textDecoration: props.fontStyle.includes("underline")
                ? "underline"
                : "none",
              textAlign: props.position,
              paddingBottom: "3px",
              lineHeight: 1, // Adjust the line spacing here
            }}
          >
            {props.freeText}
          </Typography>
        </Box>
      ) : null}

      {props.selectionChoice === "Page Number" ? (
        // Render the desired content here
        <Box>
          <Typography
            // variant="h5"
            fontSize={props.fontSize + "pt"}
            fontWeight={props.fontStyle.includes("bold") ? "bold" : "normal"}
            color={"#" + props.fontColor}
            fontFamily={props.font}
            sx={{
              fontStyle: props.fontStyle.includes("italic")
                ? "italic"
                : "normal",
              textDecoration: props.fontStyle.includes("underline")
                ? "underline"
                : "none",
              textAlign: props.position,
            }}
          >
            {props.pageStyle} {formatReturn(props.pageNumberStyle)}
          </Typography>
        </Box>
      ) : null}

      {props.selectionChoice === "image" ? (
        props.imageID !== null ? (
          <Box
            display="flex"
            justifyContent={props.position}
            sx={{ paddingBottom: "3px" }}
          >
            <img
              src={`${host}/organizations/${default_organization_id}/document_templates/${documentTemplate.id}/document_images_png/${props.imageID}/show.png`}
              alt="Loading..."
              style={{
                borderRadius: "1px",
                maxWidth: "75.6px",
                maxHeight: "0.5cm",
              }}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent={props.position}
            sx={{ paddingBottom: "3px" }}
          >
            <p>Please upload an image.</p>
          </Box>
        )
      ) : null}

      {props.selectionChoice === "none" ? (
        // Render the desired content here
        <div></div>
      ) : null}
    </Box>
  );
}
