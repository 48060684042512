import { useState, useEffect } from "react";

function useValidateReferences(finalData: any[]): {
  isValid: boolean;
  error: string | null;
} {
  const [error, setError] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    let validationError: string | null = null;
    const existingElementIds = new Set(
      finalData.flatMap((section) =>
        section.selectedElements.map((el: any) => el.id)
      )
    );

    const existingSectionIds = new Set(
      finalData.map((section) => section.sectionId)
    );

    const existingTableIds = new Set(
      finalData.flatMap((section) =>
        section.selectedElements
          .filter(
            (el: any) =>
              el.contentType === "TABLE" || el.contentType === "IMAGE"
          )
          .map((el: any) => el.id)
      )
    );

    const refRegex = /##(e|s|et)(\d+)##/g;

    // Loop through all selected elements in finalData
    for (const section of finalData) {
      for (const element of section.selectedElements) {
        const { content } = element;
        let match;

        while ((match = refRegex.exec(content)) !== null) {
          const refType = match[1]; // Either "e", "s", or "et"
          const refId = parseInt(match[2], 10); // Extract ID from the match
          const error = 'You are about to include content that has references to other content not selected. Referenced content: '
          if (refType === "e") {
            if (!existingElementIds.has(refId)) {
              validationError = `${error} Subsection ${refId}`;
              break;
            }
          } else if (refType === "s") {
            if (!existingSectionIds.has(refId)) {
              validationError = `${error} Section ${refId}`;
              break;
            }
          } else if (refType === "et") {
            if (!existingTableIds.has(refId)) {
              validationError = `${error} Table/Image ${refId}`;
              break;
            }
          }
        }
        if (validationError) break;
      }
      if (validationError) break;
    }

    if (validationError) {
      setIsValid(false);
      setError(validationError);
    } else {
      setIsValid(true);
      setError(null);
    }
  }, [finalData]);

  return { isValid, error };
}

export default useValidateReferences;
