import { MarkChatUnreadOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Popover,
  Tooltip,
  IconButton,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommentsCard from "./CommentsCard";
import AddCommentCard from "./AddCommentCard";
import {
  apiAddComments,
  apiDeleteComment,
  apiGetComments,
  apiUpdateComment,
  apiResolveComment,
  apiUnResolveComment,
} from "src/services/shareAndCommentsAPI";
import { useAppSelector } from "src/app/hooks";
import { selectEditingDocumentTemplate } from "../editor/editorSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllComments,
  setAllDocComments,
  setCommentIds,
  setSelectedCommentingItem,
} from "./commentingDetailsSlice";
import { RootState } from "src/app/store";
import {
  getCommenterId,
  getFormattedContentByWord,
  isMarkupComment,
  isUserFromShareLink,
} from "../editor/document/TableElement/EditContent/helper";
import { selectUser } from "../auth/authSlice";
import {
  AddCommentOutlined,
  CancelRounded,
  ContentCopy,
} from "@mui/icons-material";
import { setCurrentComments } from "./commentingDetailsSlice";
import { blue, grey, purple, red, yellow } from "@mui/material/colors";
import { log } from "console";
import HighlightedComment from "./HighlightComment";
import { UTCtoLocalTime } from "../editor/document/utils";

type Props = {
  data?: any;
};

const drawerWidth = "19vw";

const CommentsContainer = (props: Props) => {
  const [comment, setcomment] = useState("");
  const [selectedComment, setselectedComment] = useState<any>(null);
  const [commentSent, setcommentSent] = useState(false);
  const [editComment, seteditComment] = useState<any>(null);
  const [editableComment, seteditableComment] = useState<any>("");
  const [editcommentSent, seteditcommentSent] = useState(false);
  const [isCommentsLoading, setisCommentsLoading] = useState(false);
  const [isresolved, setisresolved] = useState(false);

  const dispatch = useDispatch();
  const [arr, setarr] = useState([
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
  ]);
  const selectedItemObject = useSelector(
    (state: RootState) => state.apiCommenting.selectedItem
  );

  // console.log("SELECTED ITEM", selectedItemObject)

  const isPopOverOpen = useSelector(
    (state: RootState) => state.apiCommenting.isPopOverOpen
  );
  const subSectionData = selectedItemObject?.subSectionData;
  const sectionData = selectedItemObject?.sectionData;
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const getAllComments = useSelector(
    (state: RootState) => state.apiCommenting.allComments
  );

  const getAllDocComments = useSelector(
    (state: RootState) => state.apiCommenting.allDocComments
  );

  const [filter, setFilter] = useState<"unresolved" | "resolved" | "all">(
    "unresolved"
  );

  const filteredComments = getAllComments?.filter((comment: any) =>
    filter === "unresolved"
      ? !comment.is_resolved
      : filter === "all"
      ? comment.is_resolved || !comment.is_resolved
      : comment.is_resolved
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value as "unresolved" | "resolved");
  };

  const { name, email, commenter_id } = useSelector(
    (state: RootState) => state.apiCommenting.commenter
  ) as any;
  const userData = useAppSelector(selectUser);

  const selectedDocumentTemplate: any = useAppSelector(
    selectEditingDocumentTemplate
  );

  const { organization_id, workspace_id, id } = selectedDocumentTemplate as any;

  const showUserIcons: any = (commenter: any) =>
    commenter_id === commenter || userData?.user_id === commenter;
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    if (!editComment) scrollToBottom();
  }, [arr]);

  const _handleallcomments = async () => {
    const _payload = {
      document_section_id: null,
      document_sub_section_id: null,
      document_element_id: null,
    };

    try {
      const response: any = await apiGetComments(
        organization_id,
        workspace_id,
        id,
        _payload
      );
      if (response.data) {
        setisCommentsLoading(false);
        dispatch(setAllDocComments(response.data.data));
        dispatch(setAllComments(response.data.data));
        dispatch(setCurrentComments(response.data.data));
      }
    } catch (error) {
      setisCommentsLoading(false);
    }
  };

  const getItemComments = async () => {
    const document_sub_section_id =
      subSectionData?.elements[0]?.document_sub_section_id ||
      subSectionData?.id;
    const document_element_id = subSectionData?.elements?.[0]?.id || null;
    const document_section_id = sectionData?.id;

    const _payload = {
      document_section_id,
      document_sub_section_id,
      document_element_id,
    };

    try {
      const response: any = await apiGetComments(
        organization_id,
        workspace_id,
        id,
        _payload
      );
      if (response.data) {
        setisCommentsLoading(false);
        dispatch(setAllComments(response.data.data));
        dispatch(setCurrentComments(response.data.data));
      }
    } catch (error) {
      setisCommentsLoading(false);
    }
  };
  const _handleAddComments = async (sectionId: number) => {
    const dummyComment = {
      comment,
      commenter_id: getCommenterId() ? commenter_id : null,
      content_type: "COMMENT",
      created_at: new Date().toLocaleString(),
      deleted_at: null,
      document_element_id:
        selectedComment?.document_element_id ||
        subSectionData?.elements?.[0]?.id,
      document_section_id: sectionId,
      document_sub_section_id:
        selectedComment?.document_sub_section_id ||
        subSectionData?.elements[0]?.document_sub_section_id,
      document_template_id: id,
      is_resolved: false,
      name,
      organization_id,
      resolved_by: null,
      supercedes_id: selectedComment ? selectedComment?.id : null,
      updated_at: null,
      user_id: 0,
      workspace_id,
    };
    dispatch(setAllComments([...getAllComments, dummyComment]));

    const _payload = {
      document_sub_section_id:
        selectedComment?.document_sub_section_id ||
        subSectionData?.elements[0]?.document_sub_section_id,
      document_element_id:
        selectedComment?.document_element_id ||
        subSectionData?.elements?.[0]?.id,
      comment,
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
      supercedes_id: selectedComment ? selectedComment.id : null,
    };
    await apiAddComments(
      organization_id,
      workspace_id,
      id,
      sectionId,
      _payload
    );

    setcomment("");
    setselectedComment(null);
  };

  const _handleEditComment = async (
    updatedComment: string,
    previousComment: string
  ) => {
    const isMarkedup = isMarkupComment(previousComment);

    const formattedComment = isMarkedup
      ? getFormattedContentByWord(previousComment, updatedComment)
      : updatedComment;

    const _payload = {
      comment: formattedComment,
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
      supercedes_id: selectedComment ? selectedComment.id : null,
    };

    const response = await apiUpdateComment(
      organization_id,
      workspace_id,
      id,
      sectionData?.id || editComment?.document_section_id,
      editComment.id,
      _payload
    );

    if (response.data) {
      seteditComment(null);
      seteditableComment(null);
      seteditcommentSent((prev: any) => !prev);
    }
  };

  const _handleDeleteComment = async (comment_id: any, sectionId: number) => {
    dispatch(
      setAllComments(
        getAllComments?.filter((comment: any) => comment.id !== comment_id)
      )
    );

    const _payload = {
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
    };

    try {
      await apiDeleteComment(
        organization_id,
        workspace_id,
        id,
        sectionId,
        comment_id,
        _payload
      );
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const _handleResolveComment = async (comment_id: any, sectionId: number) => {
    const subCommentsIds = getAllComments
      ?.filter(
        (subComment: any, _i: number) => subComment.supercedes_id === comment_id
      )
      .map((_: any) => _.id);
    const _payload = {
      comments: [comment_id, ...subCommentsIds],
    };
    const response = await apiResolveComment(
      organization_id,
      workspace_id,
      id, // Document template id
      sectionId,
      _payload
    );
    if (response) {
      getItemComments();
    }
  };

  // console.log("EDIT COMMENT !", editComment)

  const _handleUnResolveComment = async (
    comment_id: any,
    sectionId: number
  ) => {
    const subCommentsIds = getAllComments
      ?.filter(
        (subComment: any, _i: number) => subComment.supercedes_id === comment_id
      )
      .map((_: any) => _.id);
    const _payload = {
      comments: [comment_id, ...subCommentsIds],
    };
    const response = await apiUnResolveComment(
      organization_id,
      workspace_id,
      id, // Document template id
      sectionId,
      _payload
    );
    if (response) {
      getItemComments();
    }
  };

  const commentsEndRef = useRef<HTMLDivElement | null>(null);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAll(event.target.checked);
  };

  useEffect(() => {
    if (!isPopOverOpen) {
      if (selectedItemObject) {
        getItemComments();
      } else {
        _handleallcomments();
      }
    }
  }, [
    selectedItemObject,
    editComment,
    editcommentSent,
    commentSent,
    isresolved,
    isPopOverOpen,
  ]);

  useEffect(() => {
    if (!getAllComments) {
      setisCommentsLoading(true);
    } else {
      setisCommentsLoading(false);
    }
  }, [getAllComments]);

  useEffect(() => {
    if (getAllComments?.length && commentsEndRef.current && !editComment) {
      setTimeout(() => {
        commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [getAllComments]);


  useEffect(() => {
    if (getAllDocComments !== undefined) {
      const commentIds = getAllDocComments?.flatMap((comment: any) => ({
        id: comment.document_sub_section_id
          ? comment.document_sub_section_id
          : comment.document_section_id,
        supercedes_id: comment.supercedes_id,
        comment_id: comment.id,
      }));

      dispatch(setCommentIds(commentIds));
    }
  }, [getAllDocComments, isCommentsLoading]);

  const { data } = props;

  const Warning = () => (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      padding={"20px"}
      justifyContent={"center"}
      justifyItems={"center"}
      justifySelf={"center"}
    >
      <MarkChatUnreadOutlined
        style={{
          textAlign: "center",
          color: "gray",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <Typography fontSize={"small"} textAlign={"center"} color={"gray"}>
        Please select any comment to display
      </Typography>
    </Box>
  );
  const ReplyCard = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: yellow[50],
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px",
          width: "98%",
        }}
      >
        <Typography
          fontSize={"small"}
          sx={{ paddingTop: "6px", paddingLeft: "8px" }}
        >
          Replying to
        </Typography>
        <Tooltip
          title="cancel reply"
          sx={{
            cursor: "pointer",
            top: 0,
            right: 0,
          }}
          onClick={() => setselectedComment(null)}
        >
          <CancelRounded sx={{ color: grey[500] }} />
        </Tooltip>
      </Box>

      <CommentsCard
        showActions={false}
        item={selectedComment}
        fromEditor={true}
        fromReply={true}
      />
    </Box>
  );
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        height: isUserFromShareLink() ? "90%" : "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          paddingX: "10px",
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={filter}
            onChange={handleFilterChange}
            aria-label="comment-filter"
          >
            <FormControlLabel
              value="unresolved"
              control={<Radio size="small" />}
              label={<Typography fontSize="12px">Unresolved</Typography>}
            />
            <FormControlLabel
              value="resolved"
              control={<Radio size="small" />}
              label={<Typography fontSize="12px">Resolved</Typography>}
            />
            <FormControlLabel
              value="all"
              control={<Radio size="small" />}
              label={<Typography fontSize="12px">All</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {selectedItemObject && (
        <Button
          sx={{
            fontSize: "10px",
            textAlign: "left",
            backgroundColor: blue[50],
          }}
          onClick={() => {
            dispatch(setSelectedCommentingItem(null));
          }}
        >
          Show All Document Comments
        </Button>
      )}

      {data ? (
        <Warning />
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            height: "85%",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {isCommentsLoading ? (
            <Box display={"flex"} justifyContent={"center"} padding={4}>
              <CircularProgress />
            </Box>
          ) : filteredComments?.length === 0 || !filteredComments ? (
            <Box display={"flex"} justifyContent={"center"} padding={4}>
              <Typography fontSize={"small"}>No Comments Yet</Typography>
            </Box>
          ) : (
            filteredComments?.map((mainComment: any, index: number) => (
              <div key={index}>
                {!mainComment.supercedes_id && (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <CommentsCard
                      showActions={true}
                      item={mainComment}
                      key={mainComment.id || index}
                      fromEditor={true}
                      isEdit={editComment ? true : false}
                      onReply={() => {
                        setselectedComment(mainComment);
                        setcomment("");
                      }}
                      onEdit={() => {
                        if (editComment && editComment.id === mainComment.id) {
                          seteditComment(null);
                          seteditableComment(null);
                        } else {
                          seteditComment(mainComment);
                          seteditableComment(mainComment.comment);
                        }
                      }}
                      onSubmitEditedComment={_handleEditComment}
                      editValue={editComment}
                      showUserIcons={showUserIcons}
                      onCommentChange={seteditableComment}
                      editedComment={editableComment}
                      onDeleteComment={() => {
                        _handleDeleteComment(
                          mainComment.id,
                          sectionData?.id || mainComment.document_section_id
                        );
                      }}
                      onResolveComment={(e: any) => {
                        e.stopPropagation();
                        _handleResolveComment(
                          mainComment.id,
                          sectionData?.id || mainComment.document_section_id
                        );
                      }}
                      onUnResolveComment={(e: any) => {
                        e.stopPropagation();
                        _handleUnResolveComment(
                          mainComment.id,
                          sectionData?.id || mainComment.document_section_id
                        );
                      }}
                    />
                  </div>
                )}

                {getAllComments
                  ?.filter(
                    (subComment: any, _i: number) =>
                      subComment.supercedes_id === mainComment.id
                  )
                  .map((subComment: any, subIndex: number) => {
                    return (
                      <div
                        key={subComment.id || subIndex}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "0",
                            bottom: "0",
                            width: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "2px",
                              backgroundColor: "#4c3fff",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                width: "20px",
                                height: "2px",
                                backgroundColor: "#4c3fff",
                              }}
                            ></div>
                          </div>
                        </div>

                        {/* CommentsCard for each sub-comment  */}
                        <div
                          style={{
                            marginLeft: "20px",
                            minWidth: "88%",
                            maxWidth: "88%",
                          }}
                        >
                          <CommentsCard
                            showActions={true}
                            item={subComment}
                            fromEditor={true}
                            isReply={true}
                            isEdit={editComment ? true : false}
                            onReply={() => {
                              setselectedComment(subComment);
                              setcomment("");
                            }}
                            onEdit={(e: any) => {
                              if (
                                editComment &&
                                editComment.id === subComment.id
                              ) {
                                seteditComment(null);
                                seteditableComment(null);
                              } else {
                                seteditComment(subComment);
                                seteditableComment(subComment.comment);
                              }
                            }}
                            onSubmitEditedComment={_handleEditComment}
                            editValue={editComment}
                            showUserIcons={showUserIcons}
                            onCommentChange={seteditableComment}
                            editedComment={editableComment}
                            onDeleteComment={() => {
                              _handleDeleteComment(
                                subComment.id,
                                sectionData?.id ||
                                  subComment.document_section_id
                              );
                            }}
                            onResolveComment={() => {
                              _handleResolveComment(
                                subComment.id,
                                sectionData?.id ||
                                  subComment.document_section_id
                              );
                            }}
                            onUnResolveComment={() => {
                              _handleUnResolveComment(
                                subComment.id,
                                sectionData?.id ||
                                  subComment.document_section_id
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            ))
          )}
          <div ref={commentsEndRef} />
        </Box>
      )}
      {selectedComment && <ReplyCard />}
      {(selectedItemObject || selectedComment) && (
        <Box
          p={0}
          sx={{ backgroundColor: selectedComment ? yellow[50] : "" }}
          style={{ marginBottom: "20px" }}
        >
          <AddCommentCard
            fromEditor={true}
            value={comment}
            onChangeValue={(e: any) => setcomment(e.target.value)}
            supercedes_id={selectedComment ? selectedComment?.id : null}
            isReply={selectedComment ? true : false}
            valid_sid={sectionData?.id || selectedComment?.document_section_id}
            valid_ssid={
              subSectionData?.id || selectedComment?.document_sub_section_id
            }
            onComment={() => {
              _handleAddComments(
                sectionData?.id || selectedComment?.document_section_id
              ).then(() => {
                setcommentSent((prev: any) => !prev);
              });
              // handleClose();
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CommentsContainer;
