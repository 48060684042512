/* WYSIWYG Editing cell, which produces and parses markdown.
Draft.js react editor.
REF: https://jpuri.github.io/react-draft-wysiwyg/#/docs.
REF: https://draftjs.org/docs/api-reference-data-conversion
Exporting and importing reference: https://www.npmjs.com/package/markdown-draft-js
*/
import React, { useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Snackbar notification integration. REF: https://www.npmjs.com/package/material-ui-snackbar-provider

import { memo } from "react";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import {
  DocumentSection,
  DocumentTemplate,
} from "../../../types/DocumentTemplate";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DocumentElementEditor from "./DocumentElementEditor";
import Typography from "@mui/material/Typography";
import { ViewPreferences } from "../header/preferencesSlice";
import { selectApprovalMode } from "../editorSlice";
import { DebugID } from "./DebugID";
import { getEditorForDocumentElement } from "./utils";
import {
  selectFormattingSection,
  selectSectionsDocumentMasterState,
} from "../header/documentMasterSlice";
import { isEditorDisabled } from "./HelperEditorFunctions";
import { IconButton, Tooltip } from "@mui/material";
import SectionSettingConfigForm from "./sectionSettings/SettingConfig";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { COLORS } from "../../shared/Constants";
import { isUserFromShareLink } from "./TableElement/EditContent/helper";
import { RootState } from "src/app/rootReducer";
import { setIsRefMissing } from "src/features/crossReferencing/crossreferenceSlice";

const SectionEditor = memo(function SectionEditor(props: {
  organizationId: number;
  workspaceId: number;
  documentTemplate: DocumentTemplate;
  section: DocumentSection;
  viewPreferences: ViewPreferences;
  isSelected: boolean;
}) {
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const [openSettingModal, setOpenSettingModal] =
    React.useState<boolean>(false);

  const dispatch = useDispatch();

  const section = props.section;
  const _documentMasterState = useAppSelector(
    (state) => selectFormattingSection(state, props.section.id),
    shallowEqual
  );

  const isLibContentOpen = useSelector(
    (state: RootState) => state.crossReferencing.isLibContentOpen
  );

  const isCommentsActivated = useSelector(
    (state: RootState) => state.apiCommenting.isCommentsActivated
  );

  return (
    <Box
      sx={{
        border: props.viewPreferences.showSections ? 2 : 0,
        borderColor: "silver.main",
        borderRadius: 0,
        boxShadow: props.viewPreferences.showSections ? 3 : 0,
        background: props.isSelected ? "#DFDFFF" : "#FFFFFF",
        marginLeft: 1,
        marginRight: 1,
        marginTop: 0.5,
        marginBottom: 0.5,
        overflow: "visible",
      }}
    >
      {props.viewPreferences.showIDs || isLibContentOpen ? (
        <DebugID
          name="Section"
          id={section.id}
          componentOrder={section.component_order}
          showCompOrder={isLibContentOpen}
        />
      ) : null}

      {!isEditorDisabled(section.heading) &&
        !approvalsEnabled &&
        !isCommentsActivated &&
        !isUserFromShareLink() && (
          <Box
            sx={{
              position: "relative",
              zIndex: 99,
            }}
          >
            <IconButton
              onClick={(e: any) => {
                setOpenSettingModal(true);
                dispatch(setIsRefMissing(false));
              }}
              disabled={
                _documentMasterState.sectionsDocumentMaster[props.section.id]
                  ? false
                  : true
              }
              style={{
                position: "absolute",
                top: 30,
                right: -25,
                transition: "none",
                margin: 0,
                padding: 0,
                width: "fit-content",
              }}
            >
              <Tooltip title="Section Formatting">
                <FormatPaintIcon
                  style={{
                    fontSize: 22,
                    outline: "none",
                    color: !_documentMasterState.isdefault
                      ? COLORS.NOMIA_BLUE
                      : "#7284A3",
                  }}
                />
              </Tooltip>
            </IconButton>
          </Box>
        )}

      {!(
        isEditorDisabled(section.heading) &&
        props.section.heading !== "Title Page"
      ) && (
        <DocumentElementEditor
          key={section.id}
          element={null}
          parentSubsection={null}
          parentSection={section}
          documentTemplate={props.documentTemplate}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          isHeading={true}
          viewPreferences={props.viewPreferences}
          forApproval={false}
          approvalsEnabled={approvalsEnabled}
          approvalOf={-1}
          readOnly={isUserFromShareLink() ? true : false}
        />
      )}
      {section
        .subsections!.filter(
          (__) => __.pending_deleted_at === null || approvalsEnabled
        )

        .map((subsection, index) => {
          if (subsection.elements!.length > 0) {
            let hasPub = null;
            let hasNew = null;
            subsection.elements!.forEach((_) => {
              if ((_ as any).valid_until === null) {
                hasNew = _;
              } else {
                hasPub = _;
              }
            });
            return (
              <Box sx={{ overflow: "visble" }} key={subsection.id + "_ss"}>
                {props.viewPreferences.showIDs ? (
                  <DebugID
                    name="Subsection"
                    id={subsection.id}
                    componentOrder={subsection.component_order}
                  />
                ) : null}

                {hasNew &&
                  getEditorForDocumentElement(
                    hasNew,
                    subsection,
                    section,
                    section,
                    props.documentTemplate,
                    props.workspaceId,
                    props.organizationId,
                    props.viewPreferences,
                    false,
                    subsection.id + "_ss_e1",
                    approvalsEnabled,
                    -1,
                    isUserFromShareLink() ? true : false
                  )}
                {hasPub &&
                  !hasNew &&
                  getEditorForDocumentElement(
                    hasPub,
                    subsection,
                    section,
                    section,
                    props.documentTemplate,
                    props.workspaceId,
                    props.organizationId,
                    props.viewPreferences,
                    false,
                    subsection.id + "_ss_e3",
                    approvalsEnabled,
                    -1,
                    isUserFromShareLink() ? true : false
                  )}
              </Box>
            );
          } else
            return (
              <div key={subsection.id}>
                <Typography align="center">
                  {/* Subsection does not contain a document element. */}
                </Typography>
                {/* Is this possible? Do we want to allow users to delete this in this scenario? */}
              </div>
            );
        })}
      {openSettingModal && (
        <SectionSettingConfigForm
          documentTemplate={props.documentTemplate}
          section={props.section}
          closeDialogHandler={() => {
            setOpenSettingModal(false);
          }}
        />
      )}
    </Box>
  );
});

export default SectionEditor;
