import React from "react";
import { Typography } from "@mui/material";

type Props = {
  item: {
    comment: string;
  };
  fromEditor?: boolean;
};

const HighlightedComment: React.FC<Props> = (props) => {
  const parseComment = (comment: string): React.ReactNode[] => {
    const regex = /(~~.*?~~|`.*?`)/g;
    const result: React.ReactNode[] = [];
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(comment)) !== null) {
      if (match.index > lastIndex) {
        result.push(
          <Typography
            fontSize={"12px"}
            textAlign={"left"}
            maxWidth={props.fromEditor ? "250px" : "100%"}
            key={`normal-${lastIndex}`}
            component="span"
            sx={{
              color: "inherit",
            }}
          >
            {comment.slice(lastIndex, match.index).replace(/^##\s?/, "")}
          </Typography>
        );
      }

      const matchedText = match[0];
      if (matchedText.startsWith("~~") && matchedText.endsWith("~~")) {
        // Removed content
        result.push(
          <Typography
            fontSize={"12px"}
            textAlign={"left"}
            maxWidth={props.fromEditor ? "250px" : "100%"}
            key={`removed-${match.index}`}
            component="span"
            sx={{
              color: "red",
              textDecoration: "line-through",
            }}
          >
            {matchedText.replace(/~~/g, "").replace(/^##\s?/, "")}
          </Typography>
        );
      } else if (matchedText.startsWith("`") && matchedText.endsWith("`")) {
        // Added content
        result.push(
          <Typography
            fontSize={"12px"}
            textAlign={"left"}
            maxWidth={props.fromEditor ? "250px" : "100%"}
            key={`added-${match.index}`}
            component="span"
            sx={{
              color: "#00C482",
              fontWeight: "bold",
            }}
          >
            {matchedText.replace(/`/g, "").replace(/^##\s?/, "")}
          </Typography>
        );
      }

      lastIndex = regex.lastIndex;
    }

    // Add remaining text after the last match
    if (lastIndex < comment.length) {
      result.push(
        <Typography
          fontSize={"12px"}
          textAlign={"left"}
          maxWidth={props.fromEditor ? "250px" : "100%"}
          key={`normal-${lastIndex}`}
          component="span"
          sx={{
            color: "inherit",
          }}
        >
          {comment.slice(lastIndex).replace(/^##\s?/, "")}
        </Typography>
      );
    }

    return result;
  };

  return (
    <Typography
      fontSize={"12px"}
      textAlign={"left"}
      maxWidth={props.fromEditor ? "250px" : "100%"}
      sx={{
        wordWrap: "break-word",
        whiteSpace: "normal",
      }}
    >
      {parseComment(props.item.comment)}
    </Typography>
  );
};

export default HighlightedComment;
