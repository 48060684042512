import {
  AddCommentOutlined,
  ContentCopy,
  PropaneSharp,
} from "@mui/icons-material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Box, Popover, Tooltip, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import DiffMatchPatch from "diff-match-patch";
import AddCommentCard from "./AddCommentCard";
import { useAppSelector } from "src/app/hooks";
import { selectEditingDocumentTemplate } from "../editor/editorSlice";
import {
  apiAddComments,
  apiGetComments,
} from "src/services/shareAndCommentsAPI";
import {
  getCommenterId,
  getFormattedContentByWord,
} from "../editor/document/TableElement/EditContent/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllComments,
  setCurrentComments,
  setFromReviewComments,
  setPopoverOpen,
  setSelectedCommentingItem,
} from "./commentingDetailsSlice";
import { RootState } from "src/app/store";
import { blue, grey, purple, red, yellow } from "@mui/material/colors";
import { selectUser } from "../auth/authSlice";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

type Props = {
  onClick?: any;
  disabled?: boolean | null;
  totalComments: number;
  psectionid?: number | null;
  ssectionid?: number | null;
  esectionid?: number | null;
  fromTables?: boolean;
  onBlur?: any;
};

const CommentsIcon = (props: Props) => {
  // State variables to manage UI and data
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [comment, setComment] = useState("");
  const [commentSent, setCommentSent] = useState(false);
  const [editCommentSent, setEditCommentSent] = useState(false);
  const [selectedComment, setSelectedComment] = useState<any>(null);
  const [isContentCopied, setisContentCopied] = useState(false);

  // Redux selectors for fetching data
  const selectedDocumentTemplate: any = useAppSelector(
    selectEditingDocumentTemplate
  );
  const commentsData = useSelector(
    (state: RootState) => state.apiCommenting.comments
  );
  const userData = useAppSelector(selectUser);
  const { organization_id, workspace_id, id } = selectedDocumentTemplate as any;

  const { commenter_id, name, email } = useSelector(
    (state: RootState) => state.apiCommenting.commenter
  ) as any;
  // Selected item data from Redux store
  const selectedItemObject = useSelector(
    (state: RootState) => state.apiCommenting.selectedItem
  );
  const { subSectionData, sectionData } = selectedItemObject || {};

  const getAllComments = useSelector(
    (state: RootState) => state.apiCommenting.allComments
  );

  // Dispatch function for managing actions
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Handler to open the popover for adding a comment
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(setPopoverOpen(true));
    setAnchorEl(event.currentTarget);
    props.onClick();
  };

  // Handler to close the popover and reset states
  const handleClose = () => {
    dispatch(setPopoverOpen(false));
    setAnchorEl(null);
    dispatch(setCurrentComments(null));
    setSelectedComment(null);
    setEditCommentSent(false);
    props.onBlur();
    setisContentCopied(false);
  };

  // Function to handle adding new comments to the document
  const _handleAddComments = async () => {
    const originalContent =
      subSectionData?.elements[0]?.content || sectionData?.heading;

    const formattedComment = getFormattedContentByWord(
      originalContent,
      comment
    );

    const dummyComment = {
      comment: isContentCopied ? formattedComment : comment,
      commenter_id: getCommenterId() ? commenter_id : null,
      content_type: "COMMENT",
      created_at: new Date().toLocaleString(),
      deleted_at: null,
      document_element_id: subSectionData?.elements?.[0]?.id,
      document_section_id: props.psectionid,
      document_sub_section_id:
        subSectionData?.elements[0]?.document_sub_section_id,
      document_template_id: id,
      is_resolved: false,
      name,
      organization_id,
      resolved_by: null,
      supercedes_id: selectedComment ? selectedComment?.id : null,
      updated_at: null,
      user_id: 0,
      workspace_id,
    };
    dispatch(setAllComments([...getAllComments, dummyComment]));
    setComment("");
    setCommentSent((prev: any) => !prev);
    setSelectedComment(null);

    const _payload = {
      document_sub_section_id: props.ssectionid,
      document_element_id: props.esectionid,
      comment: isContentCopied ? formattedComment : comment,
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
      supercedes_id: selectedComment ? selectedComment.id : null,
    };

    await apiAddComments(
      organization_id,
      workspace_id,
      id,
      props.psectionid,
      _payload
    );

    handleClose();
  };

  // Function to handle content copy to clipboard
  const handleCopyContent = () => {
    setisContentCopied(true);
    const content =
      subSectionData?.elements[0]?.content ||
      sectionData?.pending_heading ||
      sectionData?.heading ||
      "No content available";
    const cleanContent = content.replace(/\*\*|__|\+\+/g, "");
    setComment(cleanContent);
    navigator.clipboard
      .writeText(cleanContent)
      .then(() => {
        enqueueSnackbar("Content copied to clipboard", {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        enqueueSnackbar("Failed to copy content", {
          variant: "error",
          autoHideDuration: 3000,
        });
        console.error("Clipboard error: ", err);
      });
  };

  useEffect(() => {
    if (comment.length === 0) {
      setisContentCopied(false);
    }
  }, [comment]);

  // Ref for scrolling the comments view
  const commentsEndRef = useRef<HTMLDivElement | null>(null);

  const { commentIds } = useSelector((state: any) => state.apiCommenting);

  // console.log("Comments ids", commentIds);

  return (
    <>
      {/* Icon to open the comment popover */}
      <IconButton
        sx={{
          position: "absolute",
          top: "-10px",
          right: -45,
        }}
        onClick={handleClick}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Tooltip title="Add Comment" placement="left">
          {commentIds
            ?.map((_: any) => _.id)
            ?.includes(
              props.ssectionid ? props.ssectionid : props.psectionid
            ) ? (
            <ChatBubbleIcon
              style={{
                fontSize: 22,
                outline: "none",
                color: "rgb(255, 0, 98)",
              }}
            />
          ) : (
            <ChatBubbleOutlineIcon
              style={{ fontSize: 22, outline: "none", color: "#7284A3" }}
            />
          )}
        </Tooltip>
      </IconButton>

      {/* Popover to show the comment input and action buttons */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableRestoreFocus
        sx={{ width: "100%", marginLeft: "22px" }}
      >
        {/* Area for additional UI components (can add scrollable content here) */}
        <Box
          sx={{
            minHeight: "0vh",
            maxHeight: "40vh",
            overflow: "auto",
            paddingTop: "2px",
          }}
        ></Box>

        {/* IconButton to copy content to clipboard */}
        {!props.fromTables && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={0.5}
            sx={{
              backgroundColor: "background.paper",
              // borderRadius: 1,
              // boxShadow: 1,
              padding: 2,
              transition: "all 0.3s ease",
            }}
          >
            <Tooltip title="">
              <Typography variant="body2" color={grey[900]}>
                Copy content & mark up
              </Typography>
            </Tooltip>
            <IconButton
              onClick={handleCopyContent}
              sx={{
                padding: 0.5,
                "&:hover": {
                  backgroundColor: grey[200],
                  transform: "scale(1.1)",
                },
              }}
            >
              <Tooltip placement="top" title="Copy Content" arrow>
                <ContentCopy style={{ color: grey[800], fontSize: "18px" }} />
              </Tooltip>
            </IconButton>
          </Box>
        )}

        {/* Comment input area */}
        <Box p={0} sx={{ backgroundColor: selectedComment ? yellow[50] : "" }}>
          <AddCommentCard
            fromEditor={true}
            value={comment}
            onChangeValue={(e: any) => setComment(e.target.value)}
            supercedes_id={selectedComment ? selectedComment?.id : null}
            valid_sid={sectionData?.id || selectedComment?.document_section_id}
            valid_ssid={
              subSectionData?.id || selectedComment?.document_sub_section_id
            }
            isReply={selectedComment ? true : false}
            onComment={() => {
              _handleAddComments();
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default CommentsIcon;
